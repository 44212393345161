export const getMailingList = () => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();

    firestore.collection('mailinglist').get().then(
      (documents) => {
        const docData = documents.docs.map(doc => doc.data())
        console.log('docData', docData);
        //const docDataJSON = JSON.stringify(docData);
        //console.log(docDataJSON);
        return docData
      }
    ).then(
      (data) => {
        dispatch({ type: 'MAILING_LIST_SUCCESS', emailList: data})
      }
    );

  }
}
