import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

//import moment from 'moment'
import moment from 'moment-timezone/builds/moment-timezone-with-data';

//Actions
import { startSelfProfileFetch, showProfileEditModal, submitSelfProfileUpdates } from '../../store/actions/selfProfileActions'
import { startProfileFetch } from '../../store/actions/profileActions'

const PlayPreferences = ({online, inPerson, setPlayPreference}) => {
    const onlineActiveClasses = 'btn btn-primary flex-grow-1 mr-1';
    const onlineInactiveClasses = 'btn btn-outline-secondary flex-grow-1 mr-1';
    const inPersonActiveClasses = 'btn btn-primary flex-grow-1 ml-1';
    const inPersonInactiveClasses = 'btn btn-outline-secondary flex-grow-1 ml-1';

    return (
        <div className="d-flex">
            <button id="online" className={online ?  onlineActiveClasses : onlineInactiveClasses} onClick={setPlayPreference}>{online ?  <i className="fas fa-check-square mr-2"></i> : null}Online</button>
            <button id="inPerson" className={inPerson ? inPersonActiveClasses : inPersonInactiveClasses} onClick={setPlayPreference}>{inPerson ?  <i className="fas fa-check-square mr-2"></i> : null}In Person</button>
        </div>
    )
}

// const ThemeBlock = ({theme}) => {
//     const themeClass = ' theme-'+theme.id;
//     const isSelected = theme.selected ? ' selected' : '';
//     return (
//         <div className={"col-6 col-md-4 col-lg-2 px-2 my-2 theme-block" + themeClass + isSelected}>
//             <div className="container theme-square theme-border-color rounded">
//                 <div className="row">
//                     <div className="col theme-preview d-flex justify-content-center align-items-center">
//                         <i className="fas fa-check-square text-white"></i>
//                     </div>
//                 </div>
//                 <div className="row">
//                     <div className="col theme-text-color text-center py-1 theme-badge-bg-color">{theme.name}</div>
//                 </div>
//             </div>
//         </div>
//     )
// }

const ActiveThemeItem = ({themes, handleThemeClick}) => {

    let themeList = [];
    // for(let i in themes.themeList) {
    //     //console.log(themes.themeList[i]);
    //     //const name = themes.themeList[i].name;
    //     //const id = themes.themeList[i].theme;
    //     //console.log('Theme name', name);
    //     //themeList.push(<ThemeBlock theme={{name: {name}, id: {id}, selected: true}} />);
    // }

    return (
        <div className="container rounded py-2 theme-picker theme-preview theme-border-color">
            <div className="row">
                <div className="col-7 d-flex align-items-center">
                    <h4 className="mb-0 theme-text-color">Tabletop Alley</h4>
                </div>
                <div className="col-5 d-flex">
                    <button className="btn btn-primary flex-grow-1 theme-button-color" onClick={handleThemeClick}>Change Theme</button>
                </div>
            </div>
            <Modal isOpen={false} className={'modal-lg mt-5 theme-picker'}>
                <ModalHeader>Choose Your Theme</ModalHeader>
                <ModalBody>
                    <div className="container">
                        <div className="row">
                            {themeList}
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

class EditProfileModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            profileMessage: this.props.editProfile.data.profileMessage,
            messageMaxCharacters: 200,

            tzList: undefined,
            selectedTimezone: undefined,

            playPreference: {
                online: undefined,
                inPerson: undefined,
            },

            profileUpdates: {},

            themes: {
                currentTheme: '0000',
                themeList: [
                    {
                        theme: '0000',
                        name: 'Tabletop Alley',
                        available: true,
                    },
                    {
                        theme: '0000',
                        name: 'Nightwish',
                        available: true,
                    }
                ]
            }    
        }
    }

    populateTimzoneList() {
        //Populate Timzeone List using Moment
        var timeZones = moment.tz.names();
        var offsetTmz=[];

        for(var i in timeZones)
        {
            offsetTmz.push(timeZones[i] + " (UTC "+moment.tz(timeZones[i]).format('Z')+")");
        }

        this.setState({
            tzList: offsetTmz
        });

    }

    componentWillMount() {
        this.populateTimzoneList();

        const { playPreference } = this.props.editProfile.data;

        this.setState({
            playPreference: {
                online: playPreference.online,
                inPerson: playPreference.inPerson,
            }
        });
    }

    componentWillReceiveProps(nextProps, nextState) {

        let nextStateDataSetInProgress = nextProps.editProfile.ui.isSetDataInProgress
        let currentStateDataSetInProgress = this.props.editProfile.ui.isSetDataInProgress;

        //When the state of isSetDataInProgress changes from true to false, it means we have successfully set the data and will close this dialog
        if(nextStateDataSetInProgress !== currentStateDataSetInProgress && !nextStateDataSetInProgress) {
            this.props.showProfileEditModal(false);
        }
    }

    handleChange = (e) => {
        //Trim text that gets too long based on the messageMaxCharacters
        if(e.target.id === "profileMessage") {
            if(e.target.value.length > this.state.messageMaxCharacters) {
                //console.log('Trimming profilemessage');
                e.target.value = e.target.value.substring(0, this.state.messageMaxCharacters);
            }
            this.setState({
                updatedMessage: true,
            });
        }

        this.setState({
          [e.target.id]: e.target.value
        });
    }

    //Submit profile updates - This is called when you press the "Save Profile" button in the Edit Profile modal
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.submitSelfProfileUpdates(this.props.fbAuth.uid, this.state.profileUpdates);

        //If the current profile is the active profile we're viewing, refetch profile data so that the info on the page looks up to date
        if(this.props.profile.ui.profileMatch) {
            this.props.startProfileFetch(this.props.profile.data.username);
        }
    }

    handleClose = () => {
        this.props.showProfileEditModal(false);
    }

    setSelectedTimezone = (e) => {
        this.setState({
            selectedTimezone: e.target.value,
            profileUpdates: {
                ...this.state.selectedTimezone,
                timezone: e.target.value
            }
        });
    }

    setNewProfileMessage = (e) => {
        this.setState({
            profileUpdates: {
                ...this.state.profileUpdates,
                profileMessage: this.state.profileMessage
            }
        });
    }

    setPlayPreference = (e) => {
        e.preventDefault();
        this.setState({
            playPreference: {
                ...this.state.playPreference,
                [e.target.id]: !this.state.playPreference[e.target.id]
            },
            profileUpdates: {
                playPreference: {
                    ...this.state.playPreference,
                    [e.target.id]: !this.state.playPreference[e.target.id]
                }
            }
        });
    }

    handleThemeClick = (e) => {
        e.preventDefault();
    }

    render() {             
        return (
            // isOpen is always left true, since we want to display it immediately when this component is added to the DOM
            <Modal isOpen={true}>
                <ModalHeader>
                    {this.props.editProfile.data.username}
                </ModalHeader>
                <ModalBody>
                    {/* Profile Message Form */}
                    <form className='theme-0000'>
                        {/* Profile Image */}
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    {/* Profile Message */}
                                    <div className="form-group">
                                        <label htmlFor="profileMessage">Profile Message</label>
                                        <textarea style={{fontSize: '14px'}} className="form-control" id="profileMessage" rows="3" value={this.state.profileMessage} onChange={this.handleChange} onBlur={this.setNewProfileMessage}></textarea>
                                        <small>Enter up to a maximum of {this.state.messageMaxCharacters} characters to appear on your profile.</small>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {/* Theme Pickers */}
                                    <div className="form-group">
                                        <label htmlFor="">Choose Theme</label>
                                        <ActiveThemeItem themes={this.state.themes} handleThemeClick={this.handleThemeClick}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                    <div className="col">
                                    {/* Play Preferences */}
                                    <div className="form-group">
                                        <label htmlFor="">Play Preference</label>
                                        <PlayPreferences online={this.state.playPreference.online} inPerson={this.state.playPreference.inPerson} setPlayPreference={this.setPlayPreference}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {/* Timezone */}
                                    <div className='form-group'>
                                        <label className='mr-2' htmlFor="selectTimeZone">Timezone</label>
                                        <select onChange={this.setSelectedTimezone} className="form-control" id="selectTimeZone">
                                            {this.props.editProfile.data.timezone ? <option>{this.props.editProfile.data.timezone}</option> : <option>Please select a time zone</option>}
                                            {this.state.tzList.map( tzItem => <option data={tzItem} key={tzItem}>{tzItem}</option> )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-outline-secondary mr-auto" onClick={this.handleClose}>Cancel</button>
                    <button className="btn btn-primary" onClick={this.handleSubmit}>Save Profile</button>
                </ModalFooter>
            </Modal>
        )
    }
}

class EditProfile extends Component {

    componentWillMount() {
        //Fetch the users profile data
        //this.props.startSelfProfileFetch(this.props.fbAuth.uid);
    }

    componentWillUnmount() {
        //If the UID of the prodile in EditProfile and the current Profile match (if there is one), refetch data so that the new profile data propagates visually
        if(this.props.profile.data.uid === this.props.fbAuth.uid) {
            this.props.startProfileFetch(this.props.profile.data.username);
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.editProfile.ui.isEditProfileOpen !== prevProps.editProfile.ui.isEditProfileOpen) {
            this.props.startSelfProfileFetch(this.props.fbAuth.uid);
        }
    }

    render() {

        const { ui } = this.props.editProfile;

        //openModalState - In order for the modal to be open I'm checking that the UI state is set to open (based on clicking the Edit Profile button) which dispatches this to the
        //selfProfileReducer state and then I'm also checking that the data is not currently being fetched since displaying it in this case will show errors.
        //TODO - At some point it will be good to add a "loading spinner" while the "isFetchInProgress" state is true.
        const openModalState = ui.isEditProfileOpen && !ui.isFetchInProgress;

        return (
            <div className="edit-profile">
                {openModalState ? <EditProfileModal {...this.props} /> : null }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      editProfile: state.selfProfile,
      profile: state.profile,
      auth: state.auth,
      fbAuth: state.firebase.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        startSelfProfileFetch: (uid) => dispatch(startSelfProfileFetch(uid)),
        showProfileEditModal: (showState) => dispatch(showProfileEditModal(showState)),
        submitSelfProfileUpdates: (uid, updates) => dispatch(submitSelfProfileUpdates(uid, updates)),
        startProfileFetch: (uid) => dispatch(startProfileFetch(uid)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile)