import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import EditProfile from '../profile/EditProfile.js'

import { signOut } from '../../store/actions/authActions'
import { fetchProfileImage, fetchSelfUsername, showProfileEditModal } from '../../store/actions/selfProfileActions'
import Logo from '../design/Logo'

const ButtonMyGroups = props => {
    const name = "My Groups";
    return (
        <button className="btn btn-primary float-right mr-3" onClick={() => {
            props.history.push("/groups");
        }} >{name}</button>
    )
}

//A user badge that shows the users profile image. If it's clicked on there's a dropdown menu with helpful items to the user.
const UserBadge = ({dropDown, editProfile, signOut, selfProfileData, goToMyProfile}) => {

    const profileImageClass = " img" + selfProfileData.data.profileImage;

    return (
        <Dropdown isOpen={dropDown.isOpen} toggle={dropDown.toggleDropdownOpen} className="d-inline-block">
            <DropdownToggle className={"profile-badge theme-border-color position-relative profile-image " + profileImageClass}></DropdownToggle>
            <DropdownMenu right>
                <DropdownItem onClick={goToMyProfile}><i className="fas fa-id-badge mr-2"></i>View My Profile</DropdownItem>
                <DropdownItem onClick={editProfile}><i className="fas fa-pen mr-2"></i>Edit Profile</DropdownItem>
                <DropdownItem onClick={signOut}><i className="fas fa-sign-out-alt mr-2"></i>Sign Out</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}

//Items on the top bar if the user is signed in
const SignedIn = props => {
    return (
        <div className="d-flex align-items-center">
            <ButtonMyGroups history={props.history}/>
            <h5 className="display-inline text-white float-left mb-0 mr-3">{props.selfProfileData.data.username}</h5>
            <UserBadge className={"float-right"} dropDown={props.dropDown} signOut={signOut} editProfile={props.editProfile} selfProfileData={props.selfProfileData} goToMyProfile={props.goToMyProfile} />
        </div>
    )
}

//Items that are shown on the top bar if the user is signed out
const SignedOut = ({signIn}) => {
    return (
        <div>
            <button className='btn btn-outline-primary' onClick={signIn}>Sign In</button>
        </div>
    )
}

class TopNav extends Component {

    state = {
        redirectToHome: false,
        redirectToEdit: false,

        dropdownOpen: false,
    }

    toggleDropdownOpen = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    handleSignOut = () => {
        this.props.signOut();
        this.setState({
            redirectToHome: true,
        });
    }

    //When the "Edit Profile" button is pressed
    handleEditProfile = () => {
        this.props.showProfileEditModal(true);
    }

    handleSignIn = () => {
        this.props.history.push('/home');
    }

    goToMyProfile = () => {
        const username =  this.props.selfProfile.data.username;
        if(username) {
            this.props.history.push('/u/'+username);
            this.forceUpdate();
        }
    }

    componentWillMount() {
        this.props.fetchProfileImage(this.props.fbAuth.uid);
        this.props.fetchSelfUsername(this.props.fbAuth.uid);
    }

    render() {

        const { uid } = this.props.fbAuth;

        //Redirect back to Home if the Sign Out button is pressed and no UID is present (meaning there is no active auth user)
        if(this.state.redirectToHome && !uid) {
            return <Redirect to="/home" />
        }

        //Redirect to the profile edit page if the Edit Profile button is pressed
        if(this.state.redirectToEdit) {
            //Active user username
            const activeUser = this.props.auth.currentUsername;
            const redirectTo = activeUser !== undefined ? "/u/"+activeUser+"/edit" : "/home";

            return <Redirect to={redirectTo} />
        }
 
        return (
            <nav className='navbar navbar-expand-lg tta-topnav bg-dark shadow border-bottom border-primary theme-border-color theme-0000'>
                <h4 className='navbar-brand text-white navbar-header my-auto'><Logo className={'mr-auto'} /></h4>
                <ul className='navbar-nav ml-auto'>
                    <li className='nav-item list-unstyled'></li>
                    <li className='nav-item list-unstyled'>
                       {uid ? <SignedIn signOut={this.handleSignOut} 
                                        editProfile={this.handleEditProfile}
                                        goToMyProfile={this.goToMyProfile}
                                        selfProfileData={this.props.selfProfile}
                                        dropDown={{isOpen: this.state.dropdownOpen, toggleDropdownOpen: this.toggleDropdownOpen}} 
                                        history={this.props.history}
                                        /> : <SignedOut signIn={this.handleSignIn} /> }
                        {uid ? <EditProfile {...this.props} /> : null}
                    </li>
                </ul>
            </nav>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        fbAuth: state.firebase.auth,
        auth: state.auth,
        profile: state.profile,
        selfProfile: state.selfProfile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        fetchProfileImage: (uid) => dispatch(fetchProfileImage(uid)),
        fetchSelfUsername: (uid) => dispatch(fetchSelfUsername(uid)),
        showProfileEditModal: (state) => dispatch(showProfileEditModal(state)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNav)