import React, { Component } from 'react'
import { connect } from 'react-redux'

import badgeDiscord from '../../assets/discord-badge.png'
import badgeTwitter from '../../assets/twitter-badge.png'

import kofiButtn from '../../assets/Ko-fi_Blue.png'

const SocialBadges = ({twitterLink, discordLink}) => {

  return (
    <div className='social-badges'>
      <a className='block mr-2' target="_blank"rel="noopener noreferrer" href={twitterLink} ><img src={badgeTwitter} alt="Twitter" /></a>
      <a className='block mr-2' target="_blank" rel="noopener noreferrer" href={discordLink} ><img src={badgeDiscord} alt="Discord" /></a>
      <a className='block btn-ko-fi' target="_blank" rel="noopener noreferrer" href={'https://ko-fi.com/I3I7TEDN'}><img src={kofiButtn} alt="Ko-fi" /></a>
    </div>
  )
}

const SiteMap = ({contactEmail}) => {
  return (
    <ul className="list-unstyled list-inline mb-0 text-center">
        <li className="list-inline-item">
          <a href={'/about'}><small>About</small></a>
        </li>
        <li className="list-inline-item">
          <a href={'mailto:'+contactEmail}><small>Contact</small></a>
        </li>
    </ul>
  )
}

class Footer extends Component {

  render() {
    return (
      <div className="page-footer bg-dark py-3">
        <div className="container">
          <div className="row">
            <div className="col-md-4 text-center text-md-left">
              <SocialBadges
                twitterLink={this.props.twitterLink}
                discordLink={this.props.discordLink}
              />
            </div>
            <div className="col-md-4 py-sm-3 py-md-0">
              <SiteMap contactEmail={this.props.contactEmail} />
            </div>
            <div className="col-md-4 text-center">
              <small className='text-light'>Copyright © 2019 Tabletop Alley</small>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    contactEmail: state.siteData.contacts.helpEmail,
    discordLink: state.siteData.socialLinks.discordLink,
    twitterLink: state.siteData.socialLinks.twitterLink,
  }
}

export default connect(mapStateToProps, null)(Footer)