const initState = {
  emaiList: undefined,
}

const adminReducer = (state =  initState, action) => {
  switch(action.type) {
    case 'MAILING_LIST_SUCCESS':
      return {
        ...state,
        emaiList: action.emailList
      }
    default:
      return state;
  }

}

export default adminReducer
