import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'
import splashReducer from './splashReducer'
import authReducer from './authReducer'
import siteReducer from './siteReducer'
import adminReducer from './adminReducer'
import profileReducer from './profileReducer'
import selfProfileReducer from './selfProfileReducer'
import profileImagesReducer from './profileImagesReducer'

//All reducers are combined using the combineReducers function as part of the Redux package
const rootReducer = combineReducers({
  admin: adminReducer,
  auth: authReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  siteData: siteReducer,
  splashPage: splashReducer,
  profile: profileReducer,
  selfProfile: selfProfileReducer,
  profileImages: profileImagesReducer,
});

//The rootReducer is 6
export default rootReducer
