const initState = {
    data: {
        username: undefined,
        profileMessage: undefined,
        timezone: undefined,
        playPreference: {
            inPerson: false,
            online: false,
        },
        profileImages: {
            selectedImage: '0000'
        }
    },

    ui: {
        isEditProfileOpen: false,
        isEditProfileImageOpen: false,
        isFetchInProgress: true,
        isSetDataInProgress: false,
    }
}

const selfProfileReducer = (state = initState, action) => {
    switch(action.type) {
        //DATA FETCHING ACTIONS
        case 'SP_FETCH_IN_PROGRESS':
            //console.log('selfProfileReducer - Profile fetch is IN PROGRESS');
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isFetchInProgress: true,
                 }
            }
        case 'SP_FETCH_IS_DONE':
            //console.log('selfProfileReducer - Profile fetch is DONE');
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isFetchInProgress: false,
                }
            }
        case 'SP_FETCH_ERROR':
            console.error('selfProfileReducer - Fetching error', action.error);
            return state
        //DATA SETTING ACTIONS
        case 'SP_SET_IN_PROGRESS':
            //console.log('selfProfileReducer - Profile SETTING is IN PROGRESS');
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isSetDataInProgress: true,
                }
            }
            
        case 'SP_SET_DATA_IS_DONE':
            //console.log('selfProfileReducer - Profile SETTING is DONE');
        return {
            ...state,
            ui: {
                ...state.ui,
                isSetDataInProgress: false,
            }
        }
        case 'SP_CLEAR_DATA':
            //console.log('Clearing data');
            return state = initState

        //SETUP OF PROFILE VISUAL DATA
        case 'SP_SET_USERNAME':
            //console.log('selfProfileReducer - Setting username to:', action.username);
            return {
                ...state,
                data: {
                    ...state.data,
                    username: action.username
                }
            }
        case 'SP_SET_PROFILE_IMAGE':
            return {
                ...state,
                data: {
                    ...state.data,
                    profileImage: action.profileImage
                }
            }
        case 'SP_SET_PROFILE_MESSAGE':
            //console.log('selfProfileReducer - Setting profile messages to:', action.profileMessage);
            return {
                ...state,
                data: {
                    ...state.data,
                    profileMessage: action.profileMessage
                }
            }
        case 'SP_SET_TIMEZONE':
            return {
                ...state,
                data: {
                    ...state.data,
                    timezone: action.timezone
                }
            }
        case 'SP_SET_PLAY_PREFS':
            return {
                ...state,
                data: {
                    ...state.data,
                    playPreference: action.playPreference
                }
            }
        //UI CONTROLS
        case 'SP_SHOW_MODAL':
            //console.log('Setting show modal state to: ', action.modalState);
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isEditProfileOpen: action.modalState
                }
            }
        default:
            return state
    }
}

export default selfProfileReducer