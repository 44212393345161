import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import { setProfileImage, toggleSelectProfileImageModal } from '../../store/actions/profileImagesActions'
import { startProfileFetch } from '../../store/actions/profileActions'
import { fetchProfileImage } from '../../store/actions/selfProfileActions'

const ProfileImage = ({id, selected, imageClick}) => {

    const imageClass = " img" + id;
    const selectedClass = selected ? " selected" : "";
    const fullClasses = "portrait-box bg-dark rounded profile-image" + imageClass + selectedClass;

    return (
        <div className="col-4 mb-4 position-relative">
            <div id={id} className={fullClasses} onClick={imageClick}></div>
        </div>
    )
}

const ProfileImageRow  = ({category, categoryImages=[], selectedImage, imageClick}) => {

    //1. Iterate through the categoryImages and create a <ProfileImage /> col div.
    let images = [];

    for(let i = 0; i < categoryImages.length; i++) {
        const image = categoryImages[i];
        const selected = selectedImage === image.id ? true : false;
        images.push(<ProfileImage key={i} id={image.id} selected={selected} imageClick={imageClick} />);
    }

    return (
        <div className='row py-3'>
            { images }
        </div>
    )
}

const ProfileImageContainer = ({selectedImage, imageList={}, imageClick}) => {

    //1. Let's turn the imageList into an array
    let imageListArray = Object.values(imageList);
    
    //2. Return the unique categories in a list
    let flags = [], categories = [];

    for(let i = 0; i < imageListArray.length; i++) {
        if( flags[imageListArray[i].category]) continue;
        flags[imageListArray[i].category] = true;
        categories.push(imageListArray[i].category);
    }

    //3. Iterate through each category and create a row
    let rows = [];
    for(let i = 0; i < categories.length; i++) {
        //3.1 If the image object in imageList has the matching category, create a row.
        const category = categories[i];

        //3.2 Iterate through the objects in the imageList and add a subset of them that matches the category to the row.
        let categoryImages = [];
        for(let image in imageList) {
            let imageObject = imageList[image];
            if(imageObject.category === category) {
                categoryImages.push(imageObject);
            }
        }

        rows.push(<ProfileImageRow key={i} category={category} categoryImages={categoryImages} selectedImage={selectedImage} imageClick={imageClick}/>);
    }

    return(
        <div className="container">
            { rows }
        </div>
    )
}

class EditProfileImage extends Component {

    onImageClick = (e) => {
        e.preventDefault();
        const uid = this.props.fbAuth.uid;
        const newImage = e.target.id;

        this.props.setProfileImage(uid, newImage);
        this.props.toggleSelectProfileImageModal(false);
    }

    componentWillUnmount() {
        //If the UID of the prodile in EditProfile and the current Profile match (if there is one), refetch data
        if(this.props.profile.data.uid === this.props.fbAuth.uid) {
            //console.log('Profiles match');
            this.props.startProfileFetch(this.props.profile.data.username);
            this.props.fetchProfileImage(this.props.fbAuth.uid);
        }
    }

    render() {
        const selectedProfileImage = this.props.profile.data.profileImage;

        const { imageList } = this.props.profileImages;

        //console.log(this.props);

        return (
            <Modal isOpen={this.props.profileImages.ui.isEditProfileImageOpen} className={'mt-5 profile-image-picker'}>
                <ModalHeader>Choose Your Profile Image</ModalHeader>
                <ModalBody>
                    {/* Profile Images */}
                    <ProfileImageContainer imageList={imageList} selectedImage={selectedProfileImage} imageClick={this.onImageClick} />
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-outline-secondary mr-auto" onClick={this.props.closeModal} >Cancel</button>
                </ModalFooter>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      profileImages: state.profileImages,
    }
  }

  const mapDispatchToProps = (dispatch) => {
      return {
        setProfileImage: (uid, image) => dispatch(setProfileImage(uid, image)),
        toggleSelectProfileImageModal: (toggle) => dispatch(toggleSelectProfileImageModal(toggle)),
        startProfileFetch: (uid) => dispatch(startProfileFetch(uid)),
        fetchProfileImage: (uid) => dispatch(fetchProfileImage(uid)),
      }
  }
  

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileImage)