import React, { Component } from 'react'
import TweetEmbed from 'react-tweet-embed'
import Scrollspy from 'react-scrollspy'
import { connect } from 'react-redux'

import Logo from '../../design/Logo.js'

import AboutImg1 from '../../../assets/about-img-1.jpg'
import AboutImg2 from '../../../assets/about-img-2.jpg'
import AboutImg3 from '../../../assets/about-img-3.jpg'
import AboutImg4 from '../../../assets/about-img-4.jpg'
import AboutImg5 from '../../../assets/about-img-5.jpg'
import AboutImg6 from '../../../assets/about-img-6.jpg'

import badgeTwitter from '../../../assets/twitter-badge.png'
import discordBadge from '../../../assets/discord-badge.png'

import kofiButtn from '../../../assets/Ko-fi_Blue.png'

import ProfilePic from '../../../assets/profile-pic@2x.jpg'

const AboutContent = () => {
  return (
    <section id='about-tta'>
      <div className='about-header bg-secondary' style={{backgroundImage: `url(${AboutImg1})`}}>
        <h1 className='text-white'>About Tabletop Alley</h1>
      </div>
      <div className='row pt-3 pb-2'>
        <div className='col'>
          <p>My name is Greg and I'm the creator of Tabletop Alley. I've played Tabletop Games for over 20 years and have a massive passion for them. Both technology and Tabletop Gaming have boomed over the course of my lifetime and we're only now seeing how technology can help make our gaming experience better.</p>
          <p>From my own experience and conversations with the community, I've noticed several challenges with Tabletop Gaming and I'd like to use my skills and experience to help solve them with Tabletop Alley.</p>
        </div>
      </div>
      <div className='row pt-2'>
        <div className='col'>
          <h3>Challenges With Tabletop Gaming</h3>
        </div>
      </div>
      <div className='row py-2'>
        <div className='col'>
          <h5>1. Gameplay Efficiency</h5>
          <h5>2. Planning & Coordinating Games</h5>
          <h5>3. Finding Groups to Play With</h5>
        </div>
      </div>
      <div className='row py-2'>
        <div className='col'>
          <p>Great apps like D&D Beyond and Roll20 are helping players play the game more efficiently. I'm really excited to see where they'll go! But there are still issues with Coordinating Games and finding the right players to play games. With Tabletop Alley, I hope to make a site that is easy to use to meet groups and players to play games that meet your needs.</p>
        </div>
      </div>
      <div className='row py-2'>
        <div className='col'>
          <h3>Essence Statement</h3>
          <h5>Tabletop Alley will be a modern day group-finder for meeting friends online and offline to play the kind of games you want to play.</h5>
        </div>
      </div>
    </section>
  )
}

const WhoContent = () => {
  return (
    <section id='who'>
      <div className='about-header bg-secondary' style={{backgroundImage: `url(${AboutImg2})`}}>
        <h1 className='text-white'>Who Am I?</h1>
      </div>
      <div className='row pt-3 pb-2'>
        <div className='col'>
          <div className=''><img src={ProfilePic} alt="Profile" className='profile-pic float-left mr-3 mb-2' /></div>
          <div className='mb-3'>I'm Greg! Tabletop Gaming has always been a huge passion of mine since my youth. I've also been a designer and successful business person for the majority of my career.</div>
          <div className=''>Tabletop Alley is a passion project for me to apply my skills and experience towards a hobby and industry that I'm passionate about. I genuinely believe that we're living in an age where technology is making our lives better and I want to see the same applied to my love and passion of Tabletop Gaming.</div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <h4>20 Years of Tabletop Gaming Experience</h4>
        </div>
        <div className='col-12'>
          <p>I started playing with AD&D 2nd Edition when I was a young teenager. I immediately fell in love with interactive storytelling. It also helped me understand game balancing and mechanics which later on helped me in my career.</p>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <h4>15 Years of Digital Design & Game Experience</h4>
        </div>
        <div className='col-12'>
          <p>I've been in the video game industry for roughly 15 years. I've worked at places like Blackbox (EA) on the Need for Speed series and Radical (Activision) on Scarface: The World is Yours, Prototype 1 and Prototype 2. Games are in my blood whether they are digital or pen and paper.</p>
          <p>I have worked as a Digital Designer, Game Designer, Scripter, Creative Director, Chief Creative Officer and Chief Operating Officer.</p>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <h4>Successful Entrepeneur</h4>
        </div>
        <div className='col-12'>
          <p>In 2012 I helped found and start BlueBat Games. We set out to make social games on Facebook and mobile and ultimately found our niche in the social casino market. I helped grow and lead BlueBat from 6 people to 35 with several large clients such as Hard Rock International.</p>
          <p>My time with BlueBat taught me how to be an entrepreneur, run and scale a digital business successfully and most importantly how to fail fast and learn from my failures.</p>
        </div>
      </div>
    </section>
  )
}

const HowContent = () => {
  return (
    <section id='how'>
      <div className='about-header bg-secondary' style={{backgroundImage: `url(${AboutImg3})`}}>
        <h1 className='text-white'>How Will I Succeed?</h1>
      </div>
      <div className='row pt-4'>
          <div className="col">
            <p>I intend to follow <a href='https://www.amazon.ca/Lean-Startup-Entrepreneurs-Continuous-Innovation/dp/0307887898/ref=sr_1_1?hvadid=229977035177&hvdev=c&hvlocphy=9001502&hvnetw=g&hvpos=1t2&hvqmt=e&hvrand=5990060641399743398&hvtargid=kwd-297113292231&keywords=lean+startup&qid=1556074868&s=gateway&sr=8-1' className='text-info'>the Lean startup methodology as popularized by Erik Reis</a> who was an advisor during my time with BlueBat and helped lead us to a multimillion dollar international business. Following my success and lessons I've learned creating a successful tech industry startup, I will apply these methods to rapidly iterate and build a platform the community wants.</p>
          </div>
      </div>
      <div className="row">
        <div className="col">
          <h4>Build What Matters</h4>
          <p>With tight communication with the community, my emphasis will be building the tools and items people need to get started right away without focusing on elements that are nice but not necessarily needed.</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h4>Make No Assumptions</h4>
          <p>The reality is that I don't know what the community wants. I only have my own experiences to go off of. I will engage the community on a regular basis on social media and Discord, as well as communicating with my most engaged Vanguard members. Assumptions will be tested as quickly and effectively as possible, by sharing lightweight mockups, wireframes, forms and User Research cycles.</p>
          <ul className="li">
            <h5>1. User Research</h5>
          </ul>
          <ul className="li">
            <h5>2. Lightweight Mockups</h5>
          </ul>
          <ul className="li">
            <h5>5. Questionnaires</h5>
          </ul>
          <ul className="li">
            <h5>4. Social Media & Discord Community Engagement</h5>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h4>Release Quickly, Release Often</h4>
          <p>Too often the focus is on releasing a perfect product. I will instead focus on releasing quickly and often. If the site doesn't work responsively at first. Who cares! I'll get there. And if the community gives me feedback that this is important to them, then great! That's where I'll focus next. It's more important to continually release small pieces of functionality than to worry about perfection at first.</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h4>Tight Iterations & Feedback Loops</h4>
          <p>If something's released that's broken and the community cares about it, then I have the perfect setup for iterating on something taking it one step closer to perfection. With every update, iterating on small elements and getting them closer and closer where they need to be for everyone to enjoy the platform. Every step of the way I'll listen to what the community needs and build upon it.</p>
        </div>
      </div>
      <div className="col">
        <div className="row">
          <h4>Data Driven Decision Making</h4>
          <p>Users are subjective but data doesn't lie. Powering the site on Google Analytics and Firebase Analytics, I can see usage patterns and where the site is failing and place an emphasis on developing the highest priority items first.</p>
        </div>
      </div>
      <div className="col">
        <div className="row">
          <h4>Scalable Technology Stack</h4>
          <p>Tabletop Alley is being developed in React and React Native using Firebase as a backend platform. Both of these technologies are built for fast development and scalable infrastructures which will allow the site to scale nicely over time as it gains in popularity and development team size.</p>
        </div>
      </div>
    </section>
  )
}

const VisionContent = () => {
  return (
    <section id="vision">
      <div className='about-header bg-secondary' style={{backgroundImage: `url(${AboutImg4})`}}>
        <h1 className='text-white'>The Vision</h1>
      </div>
      <div className="row mt-3">
        <div className="col">
          <h4>At Its Heart</h4>
          <h5>Tabletop Alley is...</h5>
          <ul>
            <li>A group finder for tabletop gamers</li>
            <li>A community building tool for tabletop enthusiasts</li>
            <li>A place to gather and share your love for games</li>
            <li>A place to meet new people and friends who share your love</li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h4>Game Systems</h4>
          <p>Tabletop Alley is system and game agnostic. We're not here to tell you what you love and who you want to meet. If you want to play D&D 5e, wonderful! Pathfinder? Great! Perhaps you're here to meet people to play Ticket to Ride or Magic the Gathering. There is no tabletop game we want to exclude.</p>
          <h5>That Said...</h5>
          <p>Based off our initial surveys most people interested in Tabletop Alley are Tabletop RPG enthusiasts who play D&D 5e. As such we'll be starting there and expanding outward. If you're passionate about another game or system, I'd love to hear from you!</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h4>How It Works</h4>
          <p>We're still heavily exploring how TTA will best serve people. At a high level you'll have a profile where you can share the type of gamer you are and where your passions and experiences lie.</p>
          <p>We'll then have a system where you can search for other similar gamers who share similar interests.</p>
          <p>You'll also be able to set up a Looking for Group or Member for a campaign you're running with a specific set of criteria. Some of these criteria are shaping up to look like:</p>
          <ul>
            <li>Desire to play online/in-person</li>
            <li>Distance willing to travel in-person</li>
            <li>Available times to play</li>
            <li>Experience level per system</li>
            <li>Goals & Preferences (ie. Roleplaying, strategy, long-term campaign, one-off)</li>
            <li>Soft-skills (ie. Friendliness, reliability, commitment)</li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h4>It Will Change Over Time</h4>
          <p>None of this is set in stone. It's up to you - the community - to help shape and grow what this site becomes. I'm very much looking forward to your engagement!</p>
        </div>
      </div>
    </section>
  )
}

const PaidContent = () => {
  return(
    <section id="paid">
      <div className='about-header bg-secondary' style={{backgroundImage: `url(${AboutImg5})`}}>
        <h1 className='text-white'>Will It Cost Money?</h1>
      </div>
      <div className="row mt-3">
        <div className="col">
          <p>I'm building TTA because I love Tabletop Gaming and have a passion for technology. I want to help build and grow - and bring together - an amazing community.</p>
          <p>My goal is to always have the core elements of the site open and available to everyone for free. There are long term goals in mind that may, at some point, cost money - either purchase to unlock features or subscriptions.</p>
          <p>I'm here to help the community and will ensure that any paid features in the future will absolutely be worth it. Ultimately I will need to find a way to sustain and support the base costs of the site. Until that time I am entirely funding it myself because this is a vision I truly believe in.</p>
        </div>
      </div>
    </section>
  )
}

const HelpContent = ({props}) => {
  const {discordLink, twitterLink, koFiLink} = props;
  return(
    <section id="help">
      <div className='about-header bg-secondary' style={{backgroundImage: `url(${AboutImg6})`}}>
        <h1 className='text-white'>How Can I Help?</h1>
      </div>
      <div className="row mt-3">
        <div className="col">
          <p>If you've made it this far I thank you genuinely for your interest! While we're still in our early days there are many ways you can help.</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h4>Sign Up For Our Newsletter</h4>
          <p>If you visit <a href="http://www.tabletopalley.com">www.tabletopalley.com</a> you can sign up for my newsletter. I'll send out monthly Email updates on the progress of the site. Not only that, every Email sign up I get is a vote of confidence for building the site. Getting your friends to visit and sign up is a great (and free) way that will sincerely help me get the site going.</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h4>Follow TTA On Twitter</h4>
          <p>If you're not into Emails, no worries! Follow TTA on Twitter and stay engaged with us. Liking and sharing our posts is a massive help to building this community and making it strong. This can genuinely help me get the word out there and grow the site.</p>
          <p>Not only that, but I am dedicated to sharing relevant and free content related to your favourite Tabletop Games</p>
          <div className='text-center mb-3'>
            <a href={twitterLink} role='button' target="_blank" rel="noopener noreferrer" className="btn btn-twitter mt-3 w-100"><img src={badgeTwitter} alt="Twitter" className="pr-2"/>Follow Twitter</a>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <h4>Join Our Discord</h4>
          <p>Do you use Discord? Join our discord server and become part of an engaged and ever growing community. Discord is a great way to communicate with me directly and give me the feedback I need to succeed at building the platform the community needs.</p>
          <div className='text-center mb-3'><a href={discordLink} role='button' target="_blank" rel="noopener noreferrer" className="btn btn-discord mt-3 w-100">
            <img src={discordBadge} alt="Discord" className="pr-2"/>Join Discord</a>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <h4>Donations are Welcome!</h4>
          <p>As I'm funding this site entirely myself I would always appreciate a little support. Even if it's very little. Any donations can be made through Ko-fi and are 100% put back into the site. In fact, I'll make sure you're credited for whatever the donation supports as well! All services I pay for such as art are comissioned from the Tabletop Gaming community and help TTA become an amazing, high quality and fun platform to use.</p>
          <div className='text-center mb-3'>
            <a className='block btn-ko-fi align-middle' target="_blank" rel="noopener noreferrer" href={koFiLink}><img src={kofiButtn} alt="Ko-fi" /></a>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <h4>Have I Missed Something?</h4>
          <p>If I've missed something and you can think of any way you or anyone you know is able to help with Tabletop Alley, please feel free to get in touch via any of the methods I've listed or feel free to send me an Email at <a href="mailto:tabletopalley@gmail.com">tabletopalley@gmail.com</a>. I'm sincerely looking forward to hearing from you!</p>
        </div>
      </div>
    </section>
  )
}

const AboutCard = ({props, content}) => {
  const Content = content;
  return(
    <div className='row mb-5'>
      <div className='col-sm-1 col-md-1 col-lg-3 d-xs-none'></div>
      <div className='col'>
        <div className='container bg-white rounded py-4 px-4 content-card'>
          <Content props={props}/>
        </div>
      </div>
      <div className='col-sm-1 col-md-1 col-lg-3 d-xs-none'></div>
    </div>
  )
}

class About extends Component {
  render() {
    return(
      <div className='about-page d-flex flex-grow-1'>
        <div id='about-navbar'>
          <div className='nav-link'>
            <Logo />
          </div>
          <Scrollspy items={ ['about-tta', 'who', 'how', 'vision', 'paid', 'help'] } currentClassName="active">
            <li>
              <a className='nav-link' href='#about-tta'>About Tabletop Alley</a>
            </li>
            <li>
              <a className='nav-link' href='#who'>Who Am I?</a>
            </li>
            <li>
              <a className='nav-link' href='#how'>How Will I Succeed?</a>
            </li>
            <li>
              <a className='nav-link' href='#vision'>The Vision</a>
            </li>
            <li>
              <a className='nav-link' href='#paid'>Will it Cost Money?</a>
            </li>
            <li>
              <a className='nav-link' href='#help'>How Can I Help Now?</a>
            </li>
          </Scrollspy>
        </div>

        <div className='container pt-5'>
          <AboutCard content={AboutContent} />
          <div className='row mb-5'>
            <div className='col'>
              <TweetEmbed id='1028731651906568192' options={{align: 'center'}} />
            </div>
          </div>
          <AboutCard content={WhoContent} />
          <AboutCard content={HowContent} />
          <AboutCard content={VisionContent} />
          <AboutCard content={PaidContent} />
          <AboutCard content={HelpContent} props={this.props}/>
        </div>

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {discordLink, twitterLink, koFiLink} = state.siteData.socialLinks;
  return {
    discordLink: discordLink,
    twitterLink: twitterLink,
    koFiLink: koFiLink,
  }
}

export default connect(mapStateToProps)(About)
