import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

class CreateGroup extends Component {
    render() {
        return(
            <Modal isOpen={this.props.isOpen}>
                <ModalHeader>Create Group</ModalHeader>
                <ModalBody>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="">Group Name</label>
                                        <input type="text" className="form-control"></input>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Description</label>
                                        <input type="text" className="form-control"></input>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">System</label>
                                        <select className="form-control" id="exampleFormControlSelect1">
                                            <option>Dungeons & Dragons 5th Edition</option>
                                            <option>Pathfinder</option>
                                            <option>13th Age</option>
                                            <option>Numenera</option>
                                            <option>Delta Green</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Play Preference</label>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col p-0 pr-2">
                                                    <button className="btn btn-primary online w-100">Online</button>
                                                </div>
                                                <div className="col p-0 pl-2">
                                                    <button className="btn btn-primary in-person w-100">In-Person</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-outline-secondary w-75" onClick={this.props.onClose} >Back</button>
                            </div>
                            <div className="col">
                                <button className="btn btn-info float-right w-75">Create Group</button>
                            </div>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        
    }
}

//connect is a higher order component middleware that wraps mapStateToProps and mapDispatchToProps around the CreateGroup component
//allowing the component to access the props from the state we put in the store "groupActions" and "groupReducer"
export default connect()(CreateGroup)