//This Site Reducer is to keep track of state of any sitewide
//Such as social media links, on/off switches, etc...

const initState = {
  socialLinks:{
    discordLink: undefined,
    twitterLink: undefined,
    koFiLink: undefined,
  },
  contacts: {
    helpEmail: undefined,
  },
}

const siteReducer = (state =  initState, action) => {
  switch (action.type) {
    case 'SET_SOCIAL_LINKS_SUCCESS':
      return {
        ...state,
        socialLinks: {
          discordLink: action.data.discordLink,
          twitterLink: action.data.twitterLink,
          koFiLink: action.data.koFiLink,
        }
      };
    case 'SET_SOCIAL_LINKS_ERROR':
      console.error('There was an error getting Social Links: ', action.error);
      return state;
    case 'SET_CONTACTS_SUCCESS':
      return {
        ...state,
        contacts: {
          helpEmail: action.data.helpEmail
        }
      }
    case 'SET_CONTACTS_ERROR':
      console.error('There was an error setting the Help Email', action.error);
      return state;
    default:
      return state
  }
}
export default siteReducer
