import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Tooltip } from 'reactstrap'

import EditProfileImage from './EditProfileImage'

//Actions
import { startProfileFetch, checkUidIsEqual, clearProfileData } from '../../store/actions/profileActions'
import { getProfileImages, toggleSelectProfileImageModal } from '../../store/actions/profileImagesActions'

//Assets
import Image_DefaultBG from '../../assets/texture-bg-1.png'
import Badge0000 from '../../assets/badges/badge_0000.png'

//import grabity from "grabity"

const ProfileImage = ({openProfileModal, profileImage, profileMatch}) => {
    const imageClass = 'img' + profileImage;
    return (
        <div className={"bg-dark rounded portrait-box border border-primary theme-border-color profile-image " + imageClass}>
            {/* Edit Button - Only show if the user is a match */}
            { profileMatch ? 
                <div onClick={openProfileModal} className='edit-overlay'>
                    <i style={{fontSize: '16px'}} className="fas fa-pen text-light"></i>
                </div> 
            : null }
        </div>
    )
}

const ProfileTop = ({username, profileMessage, playPreference, timezone, openProfileModal, profile}) => {

    const wantsOnlinePlay = playPreference.online;

    const wantsInPersonPlay = playPreference.inPerson;
    return (
        <div className="row rounded mt-5 mb-3">
            <div className="col-md-2 col-4 pr-0">
                <ProfileImage 
                    openProfileModal={openProfileModal} 
                    profileImage={profile.data.profileImage} 
                    profileMatch={profile.ui.profileMatch}/>
                
            </div>
            <div className="col-md-6 col-8 text-white d-flex flex-column">                  
                <div className="text-primary theme-text-color"><h3 className="username mb-0">{username}</h3></div>
                <div className="text-secondary"><small>{timezone}</small></div>
                <div>
                    <div className="badge badge-primary theme-bg-color" style={wantsOnlinePlay ? {opacity: '0.8'} : {opacity: '0.2'}}>Online Play</div>
                    <div className="badge badge-primary ml-1 theme-bg-color" style={wantsInPersonPlay ? {opacity: '0.8'} : {opacity: '0.2'}}>In-person Play</div>
                </div>
                <div className="flex-grow-1 d-flex align-items-end">
                    <h5 className="text-bottom text-secondary">{profileMessage ? '"'+profileMessage+'"' : null}</h5>
                </div>         
            </div>
            {/* Social Links */}
            <div className="col-md-4 col-12">
                <div className="card h-100 w-100 rounded social-card">
                    <div className="card-body">
                        {/* <div className="card-title">Social Links</div> */}
                        {/* <ul className="card-text list-group">
                            <li className="list-group-item">
                                <a href="https://twitter.com/g_hennessey"><i className="fab fa-twitter-square mr-2"></i>@g_hennessey</a>
                            </li>
                            <li className="list-group-item">
                                <a href="https://discordapp.com/"><i className="fab fa-discord mr-2"></i>hashimusashi#7306</a>
                            </li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

// const UserAbilities = ({abilities={}}) => {

//     const { reliability, friendliness, rpAbility, preparedness, gKnowledge } = abilities;

//     return (
//         <div className="container p-0">
//             <div className="row p-0">
//                 <div className="col-12 d-flex text-white ">
//                     <span className="bg-secondary rounded py-1 px-3 mr-1">{reliability}</span>
//                     <span className="bg-secondary rounded py-1 px-3 flex-grow-1 mr-1">Reliability</span>
//                 </div>
//                 <div className="col-12 d-flex text-white pt-2">
//                     <span className="bg-secondary rounded py-1 px-3 mr-1">{ friendliness }</span>
//                     <span className="bg-secondary rounded py-1 px-3 flex-grow-1 mr-1">Friendliness</span>
//                 </div>
//                 <div className="col-12 d-flex text-white pt-2">
//                     <span className="bg-secondary rounded py-1 px-3 mr-1">{ rpAbility }</span>
//                     <span className="bg-secondary rounded py-1 px-3 flex-grow-1 mr-1">Roleplaying Ability</span>
//                 </div>
//                 <div className="col-12 d-flex text-white pt-2">
//                     <span className="bg-secondary rounded py-1 px-3 mr-1">{ preparedness }</span>
//                     <span className="bg-secondary rounded py-1 px-3 flex-grow-1 mr-1">Preparedness</span>
//                 </div>
//                 <div className="col-12 d-flex text-white pt-2">
//                     <span className="bg-secondary rounded py-1 px-3 mr-1">{ gKnowledge }</span>
//                     <span className="bg-secondary rounded py-1 px-3 flex-grow-1 mr-1">Game Knowledge</span>
//                 </div>
//             </div>
//         </div>
//     )
// }

const InterestsTable = ({interests = []}) => {
    let interestsTable = [];

    for(let i=0; i < interests.length; i++)
    {
        let system = interests[i].system;
        let roleGM = interests[i].gm;
        let rolePlayer = interests[i].player;

        let interest = [
            <tr key={i}>
                <td>
                    {system}
                </td>
                <td>
                    <h5 style={{fontSize: '14px'}}>
                        <span style={roleGM ? {opacity: '1'} : {opacity: '0.5'}} className="badge badge-dark">GM</span>
                        <span style={rolePlayer ? {opacity: '1'} : {opacity: '0.5'}} className="badge badge-dark ml-1">P</span>
                    </h5>
                </td>
            </tr>
        ]
        interestsTable.push(interest);
    }

    return (
        <table className="table">
            <thead className="table-secondary">
                <tr style={{fontSize: '14px'}}>
                        <th scope='col'>
                            System
                        </th>
                        <th scope='col'>
                            Role
                        </th>
                    </tr>
            </thead>
            <tbody>
                {interestsTable}
            </tbody>
        </table>
    )
}

const AvailabilityList = ({availability=[]}) => {
    let availabilityList=[];

    for(let i=0; i < availability.length; i++) {
        let day = availability[i].day;
        let from = availability[i].from;
        let to = availability[i].to;
        availabilityList.push(<li key={i} className="list-group-item">{day} from {from}-{to}</li>);
    }

    return (
        <ul className="list-group">
            {availabilityList}
        </ul>
    )
}

const GroupsTable = ({groups=[]}) => {
    return (
        <tbody>
            <tr>
                <th scope='row' style={{fontSize: '12px'}}>
                    TTA Adventurers
                </th>
                <td className="d-xl-none">5</td>
                <td>
                    <h5 style={{fontSize: '14px'}}>
                        <span style={{opacity: '0.5'}} className="badge badge-dark">GM</span>
                        <span style={{opacity: '1'}} className="badge badge-dark ml-1">P</span>
                    </h5>
                </td>
            </tr>
        </tbody>
    )
}

const CardInterests = ({interests, availability}) => {
    return (
        <div className="card-body">
        {/* Interests */}
        <h5 className='card-title'>Interests</h5>
            <p>These are systems or board games you’re interested in playing and the role you’re comfortable with.</p>
            <div className="container px-0">
                <InterestsTable interests={interests} />
            </div>
            <div className="my-3 border-top"></div>
            <h5 className='card-title'>Availability</h5>
            <p>Here is information related to a player’s availability. Looking at these items should help guage whether the player is a match.</p>
            <AvailabilityList availability={availability} />
        </div>
    )
}

const CardGroups = () => {
    return (
        <div className="card-body">
            <h5 className='card-title'>Groups</h5>
            <p>These are a list of groups you can join. Someone can view information and statistics about your groups if they’re available, or request to join one if there is space and you’ve made the option available.</p>
            <div className="container px-0">
                <table className="table">
                    <thead className="table-secondary">
                        <tr style={{fontSize: '14px'}}>
                            <th scope='col'>
                                Group
                            </th>
                            <th scope='col' className="d-xl-none">
                                Members
                            </th>
                            <th scope='col'>
                                Role
                            </th>
                        </tr>
                    </thead>
                    <GroupsTable />
                </table>
            </div>
        </div>
    )
}

// const CardAbilities = () => {
//     return (
//         <div className="card-body">
//             <h5 className='card-title'>Abilities</h5>
//             <p>This is a group of “stats” that might help match you with other players. Some of these are self directed and others are gathered by interacting with other players</p>
//             {/* Abilities */}
//             {/* <UserAbilities abilities={abilities}/> */}
//         </div>
//     )
// }

const ProfileCard = ({content}) => {
    return (
    <div className="col-xl-4 col-lg-6 mb-3 mb-xl-0">
        {/* Card - Abilities and Interests */}
        <div className="card bg-white rounded info-card h-100">
            {content}
        </div>
    </div>
    )
}


class Profile extends Component {

    state = {
        tooltipOpen: false,
        isSignedInUser: false,
    }

    componentWillMount() {
        //When the component will mount we query the string query for the username and then search for the actual user
        const unQuery = this.props.match.params.username;
        this.props.startProfileFetch( unQuery );
    }

    componentDidUpdate(prevProps) {
        if(this.props.profile.data.uid !== prevProps.profile.data.uid && this.props.profile.data.uid) {
            //Here we check if the profile we are viewing matches that of the signed in user
            this.props.checkUidIsEqual(this.props.profile.data.uid, this.props.fbAuth.uid);
        }

        //If we switch between routes /u/user1 to /u/user2 let's clear the profile data and refetch it
        if(this.props.match.url !== prevProps.match.url) {
            const unQuery = this.props.match.params.username;
            this.props.clearProfileData();
            this.props.startProfileFetch( unQuery );
        }
    }

    toggleTooltip = () => {
        this.setState({
          tooltipOpen: !this.state.tooltipOpen
        });
    }

    // async testGrab() {
    //     let it = await grabity.grabIt("https://ddb.ac/characters/3306543/2n7RGa");
    //     this.setState({
    //         grab: it
    //     });
    //     return it;
    // }

    //When this is clicked open up a modal that lets the user choose their profile image
    openProfileModal = (e) => {
        e.preventDefault();
        this.props.toggleSelectProfileImageModal(true);
        this.props.getProfileImages();
    }
    //When this is clicked we close the image select modal
    closeSelectImageModal = () => {
        this.props.toggleSelectProfileImageModal(false);
    }

    render() {
        const { 
            username,
            profileMessage,
            playPreference,
            //abilities,
            //interests,
            //availability,
            timezone,
        } = this.props.profile.data;
       
        return (
            <div className={"profile-page d-flex flex-grow-1 flex-column " + this.state.theme}>
                {/* Edit Profile Modal - Don't Render if it hasn't been invoked */}
                {this.props.profileImages.ui.isEditProfileImageOpen ? <EditProfileImage {...this.props} closeModal={this.closeSelectImageModal} /> : null}

                <div className="container bg-white d-flex flex-grow-1 px-5 flex-column profile-body shadow-lg">
                    {/* Background Image - This will be selected by the user and comes from the server so we'll set the backgroundImage property here */}
                    <div className="profile-body-bg" style={{backgroundImage: `url(${Image_DefaultBG})`}}></div>                   
                    {/* Main Info Top Section */}
                    <ProfileTop 
                        {...this.props}
                        username={username} 
                        profileMessage={profileMessage} 
                        playPreference={playPreference} 
                        openProfileModal={this.openProfileModal}
                        timezone={timezone}/>
                    {/* Badges */}
                    <div className="row badges">
                        <div className="col-4 col-sm-3 col-md-2 text-center">
                            <div className="badge-square">
                                <img id='badge-0000' alt="badge" style={{height: '100px', width: '100px'}} src={Badge0000} />
                            </div>
                            
                            <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} target="badge-0000" toggle={this.toggleTooltip}>
                                Vanguard Badge: Those brave enough to venture forth into the front lines and join the worthy cause.
                            </Tooltip>
                        </div>
                        <div className="col-4 col-sm-3 col-md-2 text-center ">
                            <div className="badge-square"></div>
                        </div>
                        <div className="col-4 col-sm-3 col-md-2 text-center">
                            <div className="badge-square"></div>
                        </div>
                        <div className="col-4 col-sm-3 col-md-2 text-center d-none d-md-block">
                            <div className="badge-square"></div>
                        </div>
                        <div className="col-4 col-sm-3 col-md-2 text-center d-none d-md-block">
                            <div className="badge-square"></div>
                        </div>
                        <div className="col-4 col-sm-3 col-md-2 text-center d-none d-md-block">
                            <div className="badge-square"></div>
                        </div>
                    </div>

                    {/* PANEL GROUP (ROW) TOP - Abilities, Interests, Location & Groups */}
                    <div className="row py-3">
                        
                        {/* <ProfileCard content={<CardAbilities />} /> */}

                        <ProfileCard content={<CardInterests interests availability />} />

                        <ProfileCard content={<CardGroups />} />

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      profile: state.profile,
      selfProfile: state.selfProfile,
      profileImages: state.profileImages,
      fbAuth: state.firebase.auth
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        startProfileFetch: (user) => dispatch(startProfileFetch(user)),
        checkUidIsEqual: (visitingUid, currentUserUid) => dispatch(checkUidIsEqual(visitingUid, currentUserUid)),
        toggleSelectProfileImageModal: (toggle) => dispatch(toggleSelectProfileImageModal(toggle)),
        getProfileImages: () => dispatch(getProfileImages()),
        clearProfileData: () => dispatch(clearProfileData()),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Profile)