import React from 'react';

//ASSETS
import ttaLogo from '../../assets/TTA-Logomark.svg';

const ErrorNotFound = () => {
  return (
    <div className="error-not-found d-flex flex-grow-1">
      <div className="container">
        <div className='row py-5'>
          <div className='col d-flex justify-content-center'>
            <img className="tta-logo" alt="Tabletop Alley" data={ttaLogo}/>
          </div>
        </div>
        <div className='row pt-5'>
          <div className='col justify-content-center'>
            <h1 className='text-white text-center'>404 Page Not Found</h1>
            <h5 className='text-white text-center'>Looks like you rolled a 1! Try again.</h5>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorNotFound
