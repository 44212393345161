export const getProfileImages = () => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        dispatch({type: 'PI_FETCH_IN_PROGRESS'});

        const db = getFirestore();

        db.collection('collectables').doc('profileImages').get().then((snapshot) => {
            //console.log('\nProfile Images data', snapshot.data());
            let images = snapshot.data();
            dispatch({type: 'PI_SET_IMAGES', images: images});

        }).catch((err) => {
            console.error('Error fetching profile images: ', err);
        }).then(() => {
            dispatch({ type: 'PI_FETCH_COMPLETE '});
        });

    }
}

export const setProfileImage = (uid, imageId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        
        dispatch({ type: 'SP_SET_IN_PROGRESS' });

        const db = getFirestore();

        let updates = {
            profileImages: {
                selectedImage: imageId
            }
        }

        //console.log('Changing image to ', imageId, ' for profile ', uid);

        db.collection('users').doc(uid).set(updates, {merge: true}).then(() => {
            dispatch({ type: 'SP_SET_DATA_IS_DONE' });
        }).catch((err) => {
            console.error('Error updating profile', err);
        });

    }
}

export const toggleSelectProfileImageModal = (toggle) => {
    return (dispatch) => {
        dispatch({type:'TOGGLE_SELECT_PROFILE_IMAGE_MODAL', toggle: toggle});
    }
}