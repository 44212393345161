import React, { Component } from 'react'
import $ from 'jquery'
import { connect } from 'react-redux'
import { submitEmail } from '../../store/actions/splashActions'
import ReactGA from 'react-ga';
import queryString from 'query-string'
import { Modal, ModalBody } from 'reactstrap';
import Joi from 'joi';

//Assets
import ttaLogo from '../../assets/TTA-Logomark.svg'
//import darkAlleyBgTest from '../../assets/tta_alley_bg_v1.jpg'
import thankYouImage from '../../assets/tta_banner_v1.jpg'
import imgSiteMock from '../../assets/site-mock-01.jpg'

import discordLogo from '../../assets/discord-logo.png'
import discordBadge from '../../assets/discord-badge.png'
import badgeTwitter from '../../assets/twitter-badge.png'


const randomEmails = [
  'drizzt@dourden.com',
  'tyr@mightyjustice.com',
  'gandalf@thegrey.com',
  'aragorn@anduril.com',
  'gobo@maglubiyet.com',
  'lich@phylactery.com',
  'zombie@brainnnssss.com',
];


const SocialMediaButtons = ({discordLink, twitterLink}) => {
  return (
    <div className='row social-media-buttons'>
      <div className='col-md-6'>
        <a href={discordLink} role='button' target="_blank" rel="noopener noreferrer" className="btn btn-discord mt-3"><img src={discordBadge} alt="Discord" className=""/><p className="">Join Discord</p></a>
      </div>
      <div className='col-md-6'>
        <a href={twitterLink} role='button' target="_blank" rel="noopener noreferrer" className="btn btn-twitter mt-3"><img src={badgeTwitter} alt="Twitter" className=""/><p className="">Follow Twitter</p></a>
      </div>
    </div>
  )
}

const SubmittedModal = ({isOpen, onClick, affiliateID, discordLink, twitterLink}) => {

  ReactGA.event({
    category: 'Conversion',
    action: 'Submitted Email',
    label: affiliateID
  });

  return (
    <Modal isOpen={isOpen} onClick={onClick} className="thank-you-modal modal-lg modal-dialog-centered">
      <ModalBody>
        <div className='row'>
          <div className="col-12 center-align">
            <center><h1 className="center-align">Thank You!</h1></center>
            <div className="thank-you-image" style={{backgroundImage: `url(${thankYouImage})`, backgroundSize: 'cover'}}></div>
            <p className="left-align">We're looking forward to your involvement in helping grow the tabletop community.</p>
            <p className="left-align">Stay in couch by joining our Discord community. Become a permanent <span className="vanguard-text" style={{color: '#a17800'}}>Vanguard</span> member and help us shape the future of tabletop gaming.</p>
          </div>
          <div className='discord-col col-sm-12'>
            <SocialMediaButtons discordLink={discordLink} twitterLink={twitterLink} />
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

//I want to move away from Joi eventually. It's too complicated unless I can
//Find someone to write a nice schema using this.
const emailSchema = Joi.object().keys({
  email: Joi.string().email({ minDomainAtoms: 2 })
});


class SplashPage extends Component {

  constructor(props) {
    super(props);

    const randEmail = randomEmails[Math.floor(Math.random() * randomEmails.length)];

    this.state = {
      email: '',
      emailPlaceholder: randEmail,
      emailError: false,
      affiliateID: undefined,
      discordLink: undefined,
      twitterLink: undefined,
      modalOpen: false,
    }

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen
    }));
  }

  componentDidMount() {

    //Get the page query queryString
    const { affID } = queryString.parse(this.props.location.search);

    //console.log(this.props.splashPage.pageContent.discordLink);

    //Set first state
    this.setState({
      affiliateID: affID ? affID : 'organic',
      discordLink: this.props.splashPage.pageContent.discordLink,
      twitterLink: this.props.splashPage.pageContent.twitterLink
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    //Validate the Email field using Joi
    const result = Joi.validate({ email: this.state.email }, emailSchema);

    //Pop up the thank you modal if the field isn't empty
    if(result.error !== null) {
      //Block the submit if the email field is empty
      this.setState({
        emailError: true
      });
    } else {
      //Submit the email through the splashActions async redux action
      this.props.submitEmail(this.state.email, this.state.affiliateID);
      //Hide the input field
      $('.submit-email-form').addClass('d-none');
      //Pop-up the modal
      this.toggleModal();
      //Clear validation Errors
      this.setState({
        emailError: false,
      });

    }
  }

  render() {
    return (
      <div className="splash-page flex flex-grow-1 tta-bg-alley">
        <div className='container text-light align-middle flex vertical-center'>
          <div className="row splash-header">
            <div className='col justify-content-center d-flex'>
              <object aria-label='Tabletop Alley' className="tta-logo center-align" type="image/svg+xml" data={ttaLogo}></object>
            </div>
          </div>

          <div className='row'>
            <div className='col justify-content-center d-flex text-center deep-shadow'>
              <h4>You must gather your party before venturing forth</h4>
            </div>
          </div>

          <div className="row splash-body">

            <div className="col-md-6">
              <img className="splash-image" src={imgSiteMock} alt="Site Mockup"/>
            </div>

            <div className='col-md-6 info-column deep-shadow'>
              <h4>Adventure awaits!</h4>
              <p>Are you interested in participating in shaping the next generation of Tabletop Communities? Enter your email below and we'll send you updates on how you can participate.</p>

              <form className='submit-email-form' onSubmit={this.handleSubmit}>
                <div className='form-group row'>
                  <div className='col-md-12 col-lg-9'>
                    <label>Email</label>
                    <input type='email' className='form-control' id='email'onFocus={(e) => e.target.placeholder=''} aria-describedby='emailHelp' onChange={this.handleChange} placeholder={'ie. ' + this.state.emailPlaceholder} />
                  </div>
                  <div className='col-xs-3 col-lg-2'>
                    <button className='btn btn-primary w-100' style={{bottom: 0}}>Submit</button>
                  </div>
                  <div className='col-12 mt-2'>
                    {this.state.emailError ? <span className='error-text text-danger'>Please enter a valid Email address</span> : null }
                  </div>
                </div>
              </form>

              {this.props.splashPage.emailSubmitted ? <SocialMediaButtons discordLink={this.state.discordLink} twitterLink={this.state.twitterLink}/> : null}
              <small><a href="/about" target='_blank'>Read more about us before commiting to our newsletter.</a></small>
            </div>           
          </div>

          <div className="row join-discord d-flex text-center">
            <div className='col-12 deep-shadow'>
              <p>Join now and become a permanent <span className="vanguard-text amber-text">Vanguard</span> member on our Discord server.</p>
            </div>
          </div>
          <div className='row d-flex text-center'>
            <div className='col'>
              <img className='w-10 discord-logo' src={discordLogo} alt="Discord"/>
            </div>
          </div>

        </div>
        <SubmittedModal
          isOpen={this.state.modalOpen}
          onClick={this.toggleModal}
          discordLink={this.state.discordLink}
          twitterLink={this.state.twitterLink}
          affiliateID={this.state.affiliateID}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    splashPage: state.splashPage
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    submitEmail: (email, affiliateID) => dispatch(submitEmail(email, affiliateID))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SplashPage)
