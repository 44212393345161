import React from 'react'

import ttaLogo from '../../assets/TTA-Logomark.svg'

const Logo = ({className=''}) => {
  let classList = "tta-logo center-align " + className;
  return ( 
    <img src={ttaLogo} className={classList} alt="TTA Logo" ></img>
  )
}

//<object aria-label='Tabletop Alley' classList={classList} type="image/svg+xml" data={ttaLogo}></object>

export default Logo
