const initState = {
    imageList: undefined,

    ui: {
        profileFetchInProgress: false,
        isEditProfileImageOpen: false, 
    }
}

const selfProfileReducer = (state = initState, action) => {
    switch(action.type) {
        //UI STATE FOR SHOWING THE MODAL
        case 'TOGGLE_SELECT_PROFILE_IMAGE_MODAL':
            //console.log('Opening Edit Profile Image Modal');
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isEditProfileImageOpen: action.toggle
                }
            }
        //UI STATE WHEN DATA IS BEING FETCHED
        case 'PI_FETCH_IN_PROGRESS':
            return {
                ...state,
                ui: {
                    ...state.ui,
                    profileFetchInProgress: true
                }
            }
        case 'PI_FETCH_COMPLETE':
            return {
                ...state,
                ui: {
                    ...state.ui,
                    profileFetchInProgress: false
                }
            }
        //SET THE LIST OF DATA WHEN FETCHING IS COMPLETE
        case 'PI_SET_IMAGES':
            return {
                ...state,
                imageList: action.images
            }

        //HANDLE DATA FETCHING ERRORS
        default:
            return state
}
}

export default selfProfileReducer