import React, { Component } from 'react'
import { connect } from 'react-redux'
//import ReCAPTCHA from 'react-google-recaptcha'
import { signUp } from '../../store/actions/authActions'
import validator from 'validator'

//TODO - UNIQUE HASH
//Im going to generate a unique 4 digit hash for new signups. This is totally
//The wrong way to do it, but I'm just going to do it this way so that new accounts
//Have an associated # (ie. hashimusashi#4489) so that if I decide to do this the right way
//Later on then at least the early signups have one associated to start.

//Pass this validation scheme into validationErrors state. If the fields
//match at the end of the validation check, then we are valid. Each item in here
//Represents our target to pass validation
const validationScheme = {
  usernameEmpty: false, //Username is not empty
  usernameLength: true, //Username is between x and y characters (defined in state)
  emailEmpty: false, //Email is not empty
  validEmail: true, //Email is valid
  passwordEmpty: false, //Password is not empty
  passwordLength: true, //Password is x length (defined in state)
}

const SignUpSuccess = () => {
  return (
    <div className='container d-flex justify-content-center'>
      <div className='signup-thankyou bg-white rounded px-3 py-5'>
        <div className='row'>
          <div className='col text-center'>
            <h1>Thank you!</h1>
          </div>
        </div>
        <div className='row'>
          <div className='col text-center'>
            <span>There isn't much here yet but I appreciate you signing up! We'll have sign in and a profile working soon</span>
          </div>
        </div>
      </div>
    </div>
  )
}

class SignUpForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      username: '',
      minUNLength: 5,
      maxUNLength: 25,
      minPWLength: 8,
      uniqueHash: Math.floor(Math.random() * 9999),
      validationErrors: validationScheme,
    }
  }

  handleChange = (e) => {

    let value =  e.target.value;

    if(e.target.id === 'username' || e.target.id === 'password') {
      //Trimeshitespace from username
      value = value.replace(/\s/g,'')
    }

    this.setState({
      [e.target.id]: value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.handleValidation();
  }

  checkValid = (schemaObj, formFieldObj) => {
    //Check if entire form is valid
    //Alternatively I can use Object.keys to get the keys
    //Get the values of both objects. We will compare them to see if they are
    //The same
    const schemaValues = Object.values(schemaObj);
    const formValues = Object.values(formFieldObj);

    //Iterate through items and return false if we catch a mismatch. The form is
    //Invalid. Otherwise return true. The form is valid.
    for(var i = 0; i < schemaValues.length; i++) {
      if(schemaValues[i] !== formValues[i]) {
        return false
      }
      return true
    }
  }

  handleValidation = () => {
    const validation = {
      usernameEmpty: validator.isEmpty(this.state.username), // //Check if there is a valid username
      usernameLength: validator.isLength(this.state.username, {min: this.state.minUNLength, max: this.state.maxUNLength}), //Check if the username length is correct
      emailEmpty: validator.isEmpty(this.state.email), //Check if email is empty
      validEmail: validator.isEmail(this.state.email), //Check if the email is valid
      passwordEmpty: validator.isEmpty(this.state.password), //Check if password is empty
      passwordLength: validator.isLength(this.state.password, {min: this.state.minPWLength, max: undefined}), //Check if password length is correct
    }

    this.setState({
      validationErrors: validation,
    });

    //If we pass validation, then send the form info onwards
    if(this.checkValid(validationScheme, validation)) {
      this.props.signUp(this.state);
    }
  }

  render() {
    return(
      <div className='col'>
        <form onSubmit={this.handleSubmit}>
          <div className='form-group'>
            <label>Username</label>
            <input type='text' className='form-control' id='username' aria-describedby='usernameHelp' onChange={this.handleChange} value={this.state.username} />
            <small id='usernameHelp' className='form-text text-muted'>
              This is how others will identify you.
            </small>
            {this.state.validationErrors.usernameEmpty ? <span className='error-text text-danger'>Please enter a username.</span> : null}
            {!this.state.validationErrors.usernameLength && !this.state.validationErrors.usernameEmpty ? <span className='error-text text-danger'>{'Please enter a username between ' + this.state.minUNLength + ' and ' + this.state.maxUNLength + ' characters.'}</span> : null}
          </div>
          <div className='form-group'>
            <label>Email</label>
            <input type='email' className='form-control' id='email' aria-describedby='emailHelp' paceholder='Enter Email' onChange={this.handleChange}></input>
            <small id='emailHelp' className='form-text text-muted'>You'll use your Email for signing in.</small>
            {this.state.validationErrors.emailEmpty ? <span className='error-text text-danger'>Please enter an Email address.</span> : null}
            {this.props.authError ? <span className='error-text text-danger'>{this.props.authError}</span> : null}
            {!this.state.validationErrors.validEmail && !this.state.validationErrors.emailEmpty ? <span className='error-text text-danger'>Please enter a valid Email address.</span> : null}
          </div>
          <div className='form-group'>
            <label>Password</label>
            <input type='password' className='form-control' id='password' aria-describedby='pwHelp' onChange={this.handleChange} value={this.state.password}></input>
            <small id='pwHelp' className='form-text text-muted'>Must be a minumum of {this.state.minPWLength} characters. Keep it secret, keep it safe.</small>
            {this.state.validationErrors.passwordEmpty ? <span className='error-text text-danger'>Please enter a password.</span> : null}
            {!this.state.validationErrors.passwordLength && !this.state.validationErrors.passwordEmpty ? <span className='error-text text-danger'>{'Your password must be at least ' + this.state.minPWLength + ' characters long.'}</span> : null}
          </div>
          <button className='btn btn-primary mt-1 mb-3 w-100'><span>SIGN ME UP!</span></button>
          <small className='text-muted text-center w-100 d-inline-block'>Need help signing up? <a className='text-info' href={'mailto:'+this.props.contactEmail}>Contact us here.</a></small>
        </form>
      </div>
    )
  }
}

class SignUp extends Component {

  render() {
    //console.log(this.props);
    return (
      <div className='sign-up row py-3'>
        { !this.props.signupSuccess ? <SignUpForm {...this.props} /> : <SignUpSuccess /> }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    signupSuccess: state.auth.signupSuccess,
    contactEmail: state.siteData.contacts.helpEmail,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (creds) => dispatch(signUp(creds)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
