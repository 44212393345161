export const startProfileFetch = (username) => {

    //console.log('loadFullProfile started - provided username is: ', username);

    return (dispatch, getState, {getFirebase, getFirestore}) => {

        dispatch({ type: 'PROFILE_FETCH_IN_PROGRESS' });

         //Constant Database
        const db = getFirestore();

        //Query the DB to see if we return a valid user from the URL query param
        const userDoc = db.collection('users').where('username', '==', username);

        userDoc.get().then((snapshot) => {
        
            snapshot.docs.forEach(doc => {
                //Dispatch that we got the user ID. All the other info calls need the user ID so we start here.
                const data = doc.data();

                //SET THE UID 
                dispatch({type: "GET_UID_SUCCESS", uid: doc.id});

                //SET THE USERNAME
                dispatch({type: "GET_USERNAME_SUCCESS", username: data.username});

                //SET THE PROFILE MESSAGE
                if(data.profileMessage) {
                    dispatch({type: "GET_PROFILE_MESSAGE_SUCCESS", profileMessage: data.profileMessage});
                }

                //SET THE TIMZEONE
                if(data.timezone) {
                    dispatch({type: "GET_TIMEZONE_SUCCESS", timezone: data.timezone});
                }

                //SET THE PLAY PREFERENCES
                if(data.playPreference) {
                    dispatch({type: "GET_PLAY_PREFERENCES_SUCCESS", playPreference: data.playPreference});
                }

                //SET THE USERS PROFILE IMAGE
                if(data.profileImages) {
                    dispatch({ type: 'SET_USER_PROFILE_IMAGE', profileImage: data.profileImages.selectedImage });
                }

            });
        }).catch((err) => {
            dispatch('GET_UID_FAIL', err);
        }).then(() => {
            //Now the entire profile is done loading and we can show things properly
            dispatch({ type: 'PROFILE_FETCH_IS_DONE' });
        });
    }
}

export const checkUidIsEqual = (visitingUid, currentUserUid) => {
    return (dispatch) => {
        if(visitingUid === currentUserUid) {
            dispatch({ type: 'PROFILE_UID_MATCH', match: true });
        } else {
            dispatch({ type: 'PROFILE_UID_MATCH', match: false });
        }
    }
}

export const clearProfileData = () => {
    return (dispatch) => {
        dispatch({ type: 'CLEAR_PROFILE_DATA' });
    }
}