//All of the actions in this reducer are intended to be used when you view a profile.
//Alternatively they can be used when getting information for a profile edit page as well.

const initState = {

    data: {
        uid: undefined,
        username: undefined,
        profileMessage: undefined,
        playPreference: {
            online: false,
            inPerson: false,
        },
        profileImage: '',

        abilities: {
            reliability: undefined,
            friendliness: undefined,
            rpAbility: undefined,
            preparedness: undefined,
            gKnowledge: undefined,
        },
        interests: [],
        // Interests Schema
        // {
        //     system: 'D&D 5th Edition',
        //     gm: true,
        //     player: false,
        //     order: 0,
        // }
        availability: [],
        // Availability scheme
        // {
        //     day: 'Mondays',
        //     from: '5pm',
        //     to: '8pm'
        // },
        groups: undefined,
    },

    ui: {
        isFetchInProgress: false,

        //This is set so we can show certain elements if you are viewing your own profile
        profileMatch: false,
    }   
}

const profileReducer = (state = initState, action) => {
    switch(action.type) {

        //DATA FETCHING ACTIONS
        case 'PROFILE_FETCH_IN_PROGRESS':
            //console.log('profileReducer - Profile fetch is IN PROGRESS');
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isFetchInProgress: true,
                }
            }
        case 'PROFILE_FETCH_IS_DONE':
            //console.log('profileReducer - Profile fetch is DONE');
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isFetchInProgress: false 
                }
            }
        case 'PROFILE_FETCH_ERROR':
            console.error('profileReducer - Fetching error', action.error);
            return state

        case 'ADD_DATA_LISTENERS':
            return {
                ...state,
                dataListeners: action.dataListeners
            }
        case 'UNSUBSCRIBE_DATA_LISTENERS':
            return {
                ...state,
            }

        case 'PROFILE_UID_MATCH': 
        return {
            ...state,
            ui: {
                ...state.ui,
                profileMatch: action.match
            }
        }

        //SETUP PROFILE VISUAL DATA
        case 'GET_UID_SUCCESS':
            return {
                ...state,
                data: {
                    ...state.data,
                    uid: action.uid
                }
            }
        case 'GET_UID_FAIL':
            return state
        case 'GET_USERNAME_SUCCESS':
            return {
                ...state,
                data: {
                    ...state.data,
                    username: action.username
                }
            }
        case 'GET_USERNAME_FAIL':
            return state
        case 'GET_PROFILE_MESSAGE_SUCCESS':
            return {
                ...state,
                data: {
                    ...state.data,
                    profileMessage: action.profileMessage,
                }
            }
        case 'SET_USER_PROFILE_IMAGE':
            return {
                ...state,
                data: {
                    ...state.data,
                    profileImage: action.profileImage
                }
            }
        case 'GET_TIMEZONE_SUCCESS':
            return {
                ...state,
                data: {
                    ...state.data,
                    timezone: action.timezone
                }
            }
        case 'GET_PROFILE_MESSAGE_FAIL':
            return state
        case 'GET_PLAY_PREFERENCES_SUCCESS':
            return {
                ...state,
                data: {
                    ...state.data,
                    playPreference: action.playPreference
                }
            }
        case 'GET_USER_ABILITIES_SUCCESS':
            return {
                ...state,
                data: {
                    ...state.data,
                    abilities: action.abilities
                }
            }
        case 'GET_USER_ABILITIES_FAIL':
            return state
        case 'GET_USER_INTERESTS_SUCCESS':
            return {
                ...state,
                data: {
                    ...state.data,
                    interests: action.interests
                }
            }
        case 'GET_USER_INTERESTS_FAIL':
            return state
        case 'GET_USER_AVAILABILITY_SUCCESS':
            return {
                ...state,
                data: {
                    ...state.data,
                    availability: action.availability
                }
            }
        case 'CLEAR_PROFILE_DATA':
            return state = initState
        default:
            return state
    }
}

export default profileReducer