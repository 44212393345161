import React, { Component } from 'react'
//import { connect } from 'react-redux'
import CreateGroup from './CreateGroup.js'

const GroupCard = props => {
    return(
        <div className="card mb-4 group-card dnd-adventure-pota">
            <div className="card-body d-flex flex-column">
                {/* Group Title */}
                <div className="card-row-1">
                    <h3 className="card-title text-center mb-0">Princes of the Apocalypse</h3>
                </div>
                {/* Game Logo */}
                <div className="card-row-2 game-logo"> </div>
                {/* Number of Players */}
                <div className="card-row-3 d-flex align-items-center">
                    <div className="container bg-white num-players rounded my-2">
                        <div className="row py-1 ">
                            <div className="col-12 text-center num">
                                <h2 className="mb-0">8</h2>
                            </div>
                            <div className="col-12 text-center players">
                                Players
                            </div>
                        </div>
                    </div>
                </div>
                {/* Next Session */}
                <div className="card-row-4 next-session d-flex align-items-center">
                    <div className="container rounded">
                        <div className="row text-center">
                            <div className="col-12 title-text">Next Session</div>
                            <div className="col-12">06/23/2019 5PM-10PM</div>
                        </div>
                    </div>
                </div>
                {/* Online / In-Person */}
                <div className="card-row-5 d-flex align-items-center">
                    <div className="container my-2">
                        <div className="row">
                            <div className="col-1"></div>
                            <div className="col-5">
                                <div className="badge enabled w-100 py-1">Online</div>
                            </div>
                            <div className="col-5">
                                <div className="badge disabled w-100 py-1">In Person</div>
                            </div>
                            <div className="col-1"></div>
                        </div>
                    </div>
                </div>
                {/* Group Owner */}
                <div className="card-row-6 d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col text-center d-flex align-items-center justify-content-center">
                                <div className="profile-img d-inline-block rounded mr-1"></div>
                                <h5 className="mb-0 d-inline">Hashimusashi</h5>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Group Started */}
                <div className="card-row-7 d-flex align-items-center">
                    <div className="container group-started">
                        <div className="row">
                            <div className="col text-center">
                                Group Started 06/13/2019
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

class GroupManager extends Component {

    state = {
        createGroupOpen: false
    }

    handleCreateGroupClick = () => {
        this.setState({
            createGroupOpen: !this.state.createGroupOpen
        });
    }

    render() {
        return(
            <div className="group-manager-page d-flex flex-grow-1 flex-column">
                <div className="container bg-white flex-grow-1">
                    <div className="row my-4">
                        <div className="col">
                            <h1 className="d-inline">Groups</h1>
                            <button className="btn btn-primary ml-3">Find a Group</button>
                            <button className="btn btn-primary ml-3" onClick={this.handleCreateGroupClick}>Create Group</button>
                        </div>
                    </div>
                    {/* Group Cards Start Here */}
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <GroupCard />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <GroupCard />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <GroupCard />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <GroupCard />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <GroupCard />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <GroupCard />
                        </div>
                    </div>
                    <div className="row">

                    </div>
                </div>
                <CreateGroup isOpen={this.state.createGroupOpen} onClose={this.handleCreateGroupClick}/>
            </div> 
        )
    }
}

export default GroupManager