import React, { Component } from 'react'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import SignUp from '../../auth/SignUp.js'
import SignIn from '../../auth/SignIn.js'
import { signOut } from '../../../store/actions/authActions'

import ttaLogo from '../../../assets/TTA-Logomark.svg'

const FlowCard = (props, title) => {
  return (
    <div className='row mt-5 mb-5 pb-3'>
      <div className='col-sm-1 col-md-1 col-lg-3 d-xs-none'></div>
      <div className='col pt-2 bg-white rounded flowcard-box'>
        <div className='tta-tavern-img align-middle'>
          <h1 className='pt-5 text-white text-center align-middle'>{props.title}</h1>
        </div>
        <div className='container flowcard-content pt-3'>
          {props.children}
        </div>
      </div>
      <div className='col-sm-1 col-md-1 col-lg-3 d-xs-none'></div>
    </div>
  )
}

const SignedOutLinks = ({setStateSignUp, setStateSignIn}) => {
  return (
    <div className='row'>
      <div className='col'>
        <p>Welcome to Tabletop Alley! Have you come in search of the tavern where all great adventures begin? Well look no further, friend. You're in luck! Simply sign in below (I hope you remember the password), or create a new account.</p>
        <button className='btn btn-outline-secondary mt-3 btn-block d-inline-block' onClick={setStateSignUp}>CREATE ACCOUNT</button>
        <div className='text-center my-3'><small>-OR-</small></div>
        <button className='btn btn-primary mb-3 btn-block d-inline-block' onClick={setStateSignIn}>LOG IN</button>
      </div>
    </div>
  )
}

const BackButton = ({onClick}) => {
  return (
    <div className='row'>
      <button className='btn btn-link text-secondary' onClick={onClick}>&#60; Back</button>
    </div>
  )
}

class SignedOut extends Component {
  state = {
    flowState: 'home'
  }

  setStateSignUp = () => {
    this.setState({
      flowState: 'signup'
    });
  }

  setStateSignIn = () => {
    this.setState({
      flowState: 'signin'
    });
  }

  setStateHome = () => {
    this.setState({
      flowState: 'home'
    });
  }
  render() {
    return (
        <div className='container sign-up-container'>
          <div className='row signup-header'>
            <div className='col-sm-1 col-md-1 col-lg-3 d-xs-none'></div>
            <div className='col justify-content-center d-flex mt-5'>
              <object aria-label='Tabletop Alley' className="tta-logo center-align" type="image/svg+xml" data={ttaLogo}></object>
            </div>
            <div className='col-sm-1 col-md-1 col-lg-3 d-xs-none'></div>
          </div>

          {this.state.flowState === 'home' ? <FlowCard title="Welcome!">
            <SignedOutLinks
              setStateSignUp={this.setStateSignUp}
              setStateSignIn={this.setStateSignIn}
            />
          </FlowCard> : null}

          {this.state.flowState === 'signup' ? <FlowCard title="Create Account">
            <BackButton onClick={this.setStateHome} />
            <SignUp />
          </FlowCard>
          : null}

          {this.state.flowState === 'signin' ? <FlowCard title="Sign In">
            <BackButton onClick={this.setStateHome} />
            <SignIn />
          </FlowCard>
          : null}
        </div>
    )
  }
}

class SignedIn extends Component {

  componentWillMount() {
    //this.props.getUserName(this.props.fbAuth.uid);
  }

  render() {
    let redirectToProfile = "/u/" + this.props.userProfile.username;
    //console.log('redirectToProfile', redirectToProfile);

    // If we're signed in, let's redirect the user to their profile
    if(this.props.fbAuth.uid && this.props.userProfile.username !== undefined) {
      console.log('Home is redirecting to profile', this.props.fbAuth.uid);
      return <Redirect to={redirectToProfile} />
    }

    return (
      <div className='d-flex flex-column flex-grow-1'></div>
    )
  }
}

class HomePage extends Component {

  render() {
    const display = this.props.fbAuth.uid ? <SignedIn {...this.props} /> : <SignedOut />;

    const backgroundClass = this.props.fbAuth.uid ? '' : 'tta-bg-alley';

    return (
      <div className={'home-page d-flex flex-grow-1 ' + backgroundClass}>
        { display }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userProfile: state.firebase.profile,
    fbAuth: state.firebase.auth,
    profile: state.profile,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
