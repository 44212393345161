const initState = {
  authError: null,
  currentUsername: undefined,
  currentUserhash: undefined,
  signupSuccess: false,
  signInError: undefined,
}

const authReducer = (state =  initState, action) => {
  switch(action.type) {
    case 'LOGIN_ERROR':
      console.error('Login error:', action.err);
      return {
        ...state,
        signInError: action.err
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        signInError: null,
      }
    case 'SIGNUP_SUCCESS':
      return {
        ...state,
        authError: null,
        signupSuccess: true,
      }
    case 'SIGNUP_ERROR':
      console.error('Signup Error:', action.err);
      return {
        ...state,
        authError: action.err.message,
      }
    case 'SIGNOUT_SUCCESS':
      //console.log('Signout Success:', state);
      return {
        ...state,
      }
    case 'SIGNOUT_ERROR':
      console.error('Signout Error:', action.err);
      return {
        ...state
      }
    case 'GET_CURRENT_USER_SUCCESS':
      return {
        ...state,
        currentUsername: action.currentUsername,
        currentUserhash: action.currentUserhash,
      }
    case 'GET_CURRENT_USER_FAIL':
      return state
    default:
      return state;
  }
}

export default authReducer
