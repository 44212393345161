import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

// Initialize Firebase
var prodConfig = {
  apiKey: "AIzaSyBJSBv3KND45cFcojN-FaL4AepnxtHIjUE",
  authDomain: "tabletop-alley.firebaseapp.com",
  databaseURL: "https://tabletop-alley.firebaseio.com",
  projectId: "tabletop-alley",
  storageBucket: "tabletop-alley.appspot.com",
  messagingSenderId: "953805522218"
};

var stagingConfig = {
  apiKey: "AIzaSyAKmAPTSz-qUWZQ3gXQlimEqF2dxMs5-D4",
  authDomain: "tta-staging.firebaseapp.com",
  databaseURL: "https://tta-staging.firebaseio.com",
  projectId: "tta-staging",
  storageBucket: "tta-staging.appspot.com",
  messagingSenderId: "439723857807",
  appId: "1:439723857807:web:1a2fcd020681a0f2"
}

var config = process.env.REACT_APP_STAGE === "staging" ? stagingConfig : prodConfig;

console.log("Config environment is ", config);

firebase.initializeApp(config);

firebase.firestore().settings({})

export default firebase;