export const signIn = (credentials) => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();

    firebase.auth().signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    ).then(() => {
        //console.log('SIGN IN SUCCESS');
        dispatch({ type: 'LOGIN_SUCCESS' });
    }).catch((err) => {
        dispatch({ type: 'LOGIN_ERROR', err })
    });
  }
}

export const signOut = () => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();

    firebase.auth().signOut().then(() => {
        dispatch({ type: 'SP_CLEAR_DATA' });
        dispatch({ type: 'CLEAR_PROFILE_DATA' });
        dispatch({ type: 'SIGNOUT_SUCCESS' });
    }).catch((err) => {
        dispatch({  type: 'SIGNOUT_ERROR', err });
    });
  }
}

export const signUp = (newUser) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase.auth().createUserWithEmailAndPassword(
      newUser.email,
      newUser.password
    ).then(resp => {
      return firestore.collection('users').doc(resp.user.uid).set({
        username: newUser.username,
        firstName: '',
        lastName: '',
        uniqueHash: newUser.uniqueHash,
      });
    }).then(() => {
      dispatch({ type: 'SIGNUP_SUCCESS' });
    }).catch((err) => {
      //TODO Why dis here?
      dispatch({ type: 'SIGNUP_ERROR', err });
    })
  }
}

//Attempt to get the current user using the site so we can store their username and id for future use
export const getCurrentUser = (id) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase();
    const db = getFirestore();

    const { uid } = firebase.auth().currentUser;

    db.collection('users').doc(uid).get().then((resp) => {
      const data = resp.data();
      const currentUsername = data.username;
      const currentUserhash = data.uniqueHash;
      dispatch({ type: 'GET_CURRENT_USER_SUCCESS', currentUsername, currentUserhash });
    }).catch((err) => {
      dispatch({ type: 'GET_CURRENT_USER_FAIL', err });
    });

  }
}
