export const initSiteData = () => {
  return(dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    const db = firestore.collection('siteConfig')

    //Get social links to propagate accross the site
    getSocialLinks(dispatch, db);

    //Get the help email
    getContactInfo(dispatch, db);
  }
}

const getContactInfo = (dispatch, db) => {
  const contacts = db.doc('contacts');
  contacts.get().then(
    doc => {
      const data = doc.data();
      dispatch({
        type: 'SET_CONTACTS_SUCCESS',
        data
      });
    }
  ).catch(
    error => {
      dispatch({type: 'SET_CONTACTS_ERROR'}, error);
    }
  );
}

const getSocialLinks = (dispatch, db) => {
  const socialLinks = db.doc('socialLinks');
  socialLinks.get().then(
    doc => {
      const data = doc.data();
      dispatch({
        type: 'SET_SOCIAL_LINKS_SUCCESS',
        data
      });
    }
  ).catch(
    error => {
      dispatch({ type: 'SET_SOCIAL_LINKS_ERROR', error });
    }
  );
}