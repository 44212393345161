import React, { Component } from 'react';
import {  BrowserRouter, Switch, Route } from 'react-router-dom'
import { withRouter } from "react-router";
import Footer from './components/navigation/Footer.js'
import ReactGA from 'react-ga';

//REDUX - Site state
import { connect } from 'react-redux'
import { initSiteData } from './store/actions/siteActions.js'
import { getCurrentUser } from './store/actions/authActions.js'

import TopNav from './components/navigation/TopNav.js'

//Site Pages
import HomePage from './components/pages/home/HomePage.js'
import About from './components/pages/about/About.js'

//Landing Pages
//import ComingSoon from './components/landing/ComingSoon.js'
import SplashPage from './components/landing/SplashPage.js'

//Backoffice pages
import Admin from './components/backoffice/Admin.js'

//Error pages
import ErrorNotFound from './components/errors/Errors.js'

//Profile
import Profile from './components/profile/Profile.js'

//Groups
import GroupManager from './components/pages/groups/GroupManager.js';

//Tools
import toaEncounters from './components/adventures/toa/toaEncounters.js';

const TopNavWithRouter = withRouter(TopNav);

class App extends Component {
  constructor(props) {
    super(props);

    //Init site data
    this.props.initSiteData();

    //Initialize Google Analytics for the App
    ReactGA.initialize('UA-134604266-1');
    ReactGA.ga('require', 'GTM-M5C4V93');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    //console.log("Props in the app", this.props);
    return (
      <BrowserRouter>
        <div className="App">
            {/* The Top Nav should only display if we're signed in */}
            {this.props.fbAuth.uid ? <TopNavWithRouter /> : null}
            <Switch>
              <Route exact path="/" component={SplashPage}/>
              <Route path='/home' component={HomePage} />
              <Route path = '/about' component={About} />
              <Route path="/admin" component={Admin} />
              <Route path="/signup" component={HomePage} />
              <Route path="/groups" component={GroupManager} />
              <Route path="/u/:username" component={Profile} />
              <Route path="/toa" component={toaEncounters} />
              <Route component={ErrorNotFound} />
            </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    siteData: state.siteData,
    auth: state.auth,
    fbAuth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    initSiteData: () => dispatch(initSiteData()),
    getCurrentUser: () => dispatch(getCurrentUser())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);