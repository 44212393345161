export const showProfileEditModal = (showState) => {
    return(dispatch) => {
        dispatch({type: 'SP_SHOW_MODAL', modalState: showState});
    }
}

export const fetchProfileImage = (uid) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        
        const db = getFirestore();

        //Get the username
        db.collection('users').doc(uid).get().then((snapshot) => {

            const data = snapshot.data();

            //SET THE PROFILE IMAGE
            const profileImages = data.profileImages;
            var selectedImage;
            if (profileImages) {
                selectedImage = profileImages.selectedImage;
            }
            if(selectedImage) {
                dispatch({type: 'SP_SET_PROFILE_IMAGE', profileImage: data.profileImages.selectedImage});
            }

        }).catch((err) => {
            dispatch({type: 'SP_FETCH_ERROR', error: err}); 
        }).then(() => {
            dispatch({ type: 'SP_FETCH_IS_DONE' });
        });
    }
}

export const fetchSelfUsername = (uid) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        
        const db = getFirestore();

        //Get the username
        db.collection('users').doc(uid).get().then((snapshot) => {

            const data = snapshot.data();

            //SET THE USERNAME
            dispatch({type: 'SP_SET_USERNAME', username: data.username});

        }).catch((err) => {
            dispatch({type: 'SP_FETCH_ERROR', error: err}); 
        }).then(() => {
            dispatch({ type: 'SP_FETCH_IS_DONE' });
        });
    }
}

export const startSelfProfileFetch = (uid) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        dispatch({type: 'SP_FETCH_IN_PROGRESS'});
        
        const db = getFirestore();

        //Get the username
        db.collection('users').doc(uid).get().then((snapshot) => {

            const data = snapshot.data();

            //SET THE USERNAME
            dispatch({type: 'SP_SET_USERNAME', username: data.username});

            //SET THE PROFILE IMAGE
            const profileImages = data.profileImages;
            var profileImage;
            if (profileImages) {
                profileImage = profileImages.selectedImage;
            }
            if(profileImage) {
                dispatch({type: 'SP_SET_PROFILE_IMAGE', profileImage: profileImage});
            }

            //SET THE PROFILE MESSAGE
            const profileMessage = data.profileMessage;
            if(profileMessage) {
                dispatch({type: 'SP_SET_PROFILE_MESSAGE', profileMessage: profileMessage });
            }

            //SET THE TIMEZONE
            const timezone = data.timezone;
            if(timezone) {
                dispatch({ type: 'SP_SET_TIMEZONE', timezone: timezone });
            }

            //SET PLAY PREFERENCES
            const playPreference = data.playPreference;
            if(playPreference) {
                dispatch({ type: 'SP_SET_PLAY_PREFS', playPreference: playPreference });
            }

        }).catch((err) => {
            dispatch({type: 'SP_FETCH_ERROR', error: err}); 
        }).then(() => {
            dispatch({ type: 'SP_FETCH_IS_DONE' });
        });
    }
}

export const submitSelfProfileUpdates = (uid, updates) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const db = getFirestore();
        dispatch({ type: 'SP_SET_IN_PROGRESS' });

        db.collection('users').doc(uid).set(updates, {merge: true}).then(() => {
            dispatch({ type: 'SP_SET_DATA_IS_DONE' });
        }).catch((err) => {
            console.error('Error updating profile', err);
        });
    }
}