import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'

import AdminSignIn from './AdminSignIn'
import BackofficeFrontPage from './BackofficeFrontPage'

class Admin extends Component {
  state = {
    signedIn: false
  }

  onPropsWillUpdate(prevProps, nextProps) {
    console.log('Props are updating');
  }

  render() {
    const { profile } = this.props;
    const signedIn = profile.isAdmin;

    return(
      <div className='backoffice d-flex flex-grow-1' >
        <div className="admin-body d-flex flex-grow-1">
          {!signedIn ? <AdminSignIn props={this.props}/> : <BackofficeFrontPage props={this.props} />}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
