import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'

class SignIn extends Component {

  state = {
    email: '',
    password: '',
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signIn(this.state)
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  render() {
    return (
      <div className='row sign-in py-3'>
        <div className='col'>
          <form onSubmit={this.handleSubmit}>
            <div className='form-group'>
              <label>Email</label>
              <input type='text' className='form-control' id='email' aria-describedby='emailHelp' onChange={this.handleChange}/>
            </div>
            <div className='form-group'>
              <label>Password</label>
              <input type='password' className='form-control' id='password' aria-describedby='pwHelp' onChange={this.handleChange}/>
              { this.props.auth.signInError ? <small className='text-danger'>The username and password don't match. Please try again.</small> : null }
            </div>
            <button className='btn btn-primary btn-block d-inline-block text-uppercase mt-3'>Sign In</button>
            <small className='text-muted text-center w-100 d-inline-block mt-4'>Need help signing in? <a className='text-info' href={'mailto:'+this.props.contactEmail}>Contact us here.</a></small>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fbAuth: state.firebase.auth,
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
