import React, { Component } from 'react';

import TOAImage from '../../../assets/game_covers/5e_toa.jpg'

const d100 = 100;
const d20 = 20;
const d4 = 4;

const RollDice = (numDice, dNum, mod) => {

    let diceTotal = 0;

    if(!mod) {
        mod = 0;
    }

    for(let i=0; i<numDice; i++) {
        diceTotal = diceTotal + Math.ceil(Math.random() * dNum);
    }

    return diceTotal + mod;
}

const ENC = {
    AARAKOCRA: { name: "Aarakocra", details:'ToA 196', more: () => {return RollDice(1, 4, 1) + " aarakocra (MM 12)."}},
    ALBINO_DWARVES: {name:"Albino dwarves", details:'ToA 196', more: () => {return RollDice(1, 4, 3) + " albino dwarves (ToA 210)"}},
    ALDANI: {name:"Aldani", details: "ToA 196",  more: () => {return RollDice(1, 4) + " aldani (ToA 210)"}},
    ALMIRAJ: {name: "Almiraj", details: "ToA 196", more: () => {return RollDice(1, 6) + " almiraj (ToA 211)"}},
    APES: {name: "Apes", details: "ToA 196",  more: () => {return RollDice(1, 4, 3) + " apes (MM 317)."}},
    ARTUS_CIMBER: {name: "Artus Cimber", details: "ToA 196",  more: () => {}},
    ASSASSIN_VINES: {name: "Assassin vines", details: "ToA 196", more: () => {return RollDice(1, 3) + " assassin vines (ToA 213)"}},
    AXE_BEAKS: {name: "Axe beaks", details: "ToA 196", more: () => {return RollDice(1, 6, 3) + " axe beaks (MM 317)"}},
    BABOONS: {name: "Baboons", details: "ToA 196", more: () => {return RollDice(3, 6) + " baboons (MM 318)."}},
    CACHE: {name: "Cache", details: "ToA 196", more: () => {
        const roll = RollDice(1,20);
        const cacheTable = {
            1: "Rain catcher and mess kit",
            2: "10-day supply of preserved rations",
            3: "20-day supply of preserved rations",
            4: "50-day supply of preserved rations",
            5: RollDice(1,4) + " cask(s) of water holding 5 gallons each",
            6: RollDice(1,4) + " cask(s) of tej",
            7: RollDice(1,4) + " climber's kit(s)",
            8: "Coffer containing " + RollDice(2,4) + " vials of antitoxin",
            9: "20-day supply of insect repellant",
            10: RollDice(1,4) + " quivers, each containing " + RollDice(1,20) + " arrows",
            11: "Canoe with six paddles",
            12: "2 hooded lanters and 10 flasks of lamp oil",
            13: "Two-person tent and " + RollDice(1, 4) + " explorer's packs",
            14: "Wooden box containing " + RollDice(2, 10) + " daggers (low quality)",
            15: "Set of navigator's tools",
            16: RollDice(1, 10) + " changes of woolen clothing",
            17: "Set of cartographer's tools",
            18: "Two-person tent and " + RollDice(1, 4) + " healer's kit(s)",
            19: "2 two-person tents, folding camp table, and four folding stools",
            20: "Wooden box containing " + RollDice(2, 4) + " potions of healing"
        }
        return "Cache: " + cacheTable[roll]
    }},
    CANNIBALS: {name: "Cannibals", details: "ToA 196", more: () => {return RollDice(3, 6) + " tribal warriors (MM 350)."}},
    CHWINGA: {name: "Chwinga", details: "ToA 197", more: () => {return "1 chwinga"}},
    CROCODILES: {name: "Crocodiles", details: "ToA 197", more: () => {return RollDice(1, 4, 1) + " crocodiles (MM 320)"}},
    CYCLOPS: {name: "Cyclops", details: "ToA 197", more: () => {return "1 cyclops"}},
    DINO_ALLOSAURUS: {name: "Dinosaurs, allosaurus", details: "ToA 197", more: () => {return RollDice(1, 4) + " allosauruses (MM 79)."}},
    DINO_ANKLYOSAURUS: {name: "Dinosaurs, anklyosaurus", details: "ToA 197", more: () => {return "1 anklyosaurus (MM79)."}},
    DINO_BRONTOSAURUS: {name: "Dinosaurs, brontosaurus", details: "ToA 197", more: () => {return "1 brontosaurus (ToA 215)."}},
    DINO_DINONYCHUS: {name: "Dinosaurs, deinonychus", details: "ToA 197", more: () => {return RollDice(1, 4, 2) + " deinonychuses (ToA 217)."}},
    DINO_DIMETRODON: {name: "Dinosaurs, dimetrodon", details: "ToA 197", more: () => {return RollDice(2, 6) + " dimetrodons (ToA 217)"}},
    DINO_HADROSAURUS:{name: "Dinosaurs, hadrosaurus", details: "ToA 197", more: () => {return RollDice(3, 6) + " hadrosauruses (ToA 224)."}},
    DINO_PLESIOSAURUS:{name: "Dinosaurs, plesiosaurus", details: "ToA 198", more: () => {return "2 plesiosauruses (MM 80)."}},
    DINO_PTERANODON: {name: "Dinosaurs, pteranodon", details: "ToA 198", more: () => {return RollDice(2, 6) + " pteranodon (MM 80)."}},
    DINO_QUETZALCOATLUS: {name: "Dinosaurs, quetzalcoatlus", details: "ToA 198", more: () => {return RollDice(1, 4, 1) + " quetzalcoatluses (ToA 230)"}},
    DINO_STEGASAURUS: {name: "Dinosaurs, stagasaurus", details: "ToA 198", more: () => {return "1 stegasaurus (ToA 231)"}},
    DINO_TRICERATOPS: {name: "Dinosaurs, triceratops", details: "ToA 198", more: () => {return "1 triceratops (MM 80)"}},
    DINO_TYRANNOSAURUS: {name: "Dinosaurs, tyrannosaurus", details: "ToA 198", more: () => {
        const isFighting = RollDice(1, 100);
        let tRextDesc = "";
        const fightTable = [
            "a stegasaurus (ToA 231).",
            "a triceratops (MM 80).",
            "a pair of giant constrictor snakes (MM 324).",
            "a giant ape (MM 323).",
            "a mob of ghouls (MM 148) and zombies (MM 316)"
        ];

        if(isFighting <= 50) {
            tRextDesc = "1 tyrannosaurus (MM 80)."
        } else {
            const random = Math.ceil(Math.random() * fightTable.length);
            tRextDesc = "1 tyrannosaurus (MM 80) fighting " + fightTable[random]
        }
        return tRextDesc
    }},
    DINO_VELOCIRAPTOR: {name: "Dinosaurs, velociraptor", details: "ToA 198", more: () => {return RollDice(3, 6) + " velociraptors (ToA 235)."}},
    DRAGON_FAERIE: {name: "Dragon, faerie", details: "ToA 199",  more: () => {return "1 faerie dragon (MM 133)."}},
    DRAGON_RED: {name: "Dragon, red", details: "ToA 199", more: () => {return "1 young red dragon (MM 98)."}},
    EBLIS: {name: "Eblis", details: "ToA 199",  more: () => {return RollDice(1, 4, 1) + " eblises (ToA 219)."}},
    EMERALDENCLAVE: {name: "Emerald Enclave", details: "ToA 199", more: () => {return "1 priest (MM 348) of Meilikki, " + RollDice(1, 4) + " scouts (MM 349)."}},
    EXPLORER_DEAD: {name: "Explorer, dead", details: "ToA 199",  more: () => {return " Consult the Dead Explorers Table (ToA 198)"}},
    EXPLORERS: {name: "Explorers", details: "ToA 199", more: () => {
        const roll = RollDice(1,6);
        let explorerStatus = "";
        if(roll > 2 && roll < 5) {
            explorerStatus = "The explorers are in good shape but are actively hunted by firenewts, ghouls, goblins, or grungs."
        } else if(roll === 5) {
            explorerStatus = "The explorers are healthy and headed toward the nearest landmark, intent on exploring it."
        } else if (roll === 6) {
            explorerStatus = "The explorers are healthy and heading back to Port Nyanzaru for rest and supplies."
        } else {
            explorerStatus = "The explorers are lost and hungry."
        }
        return explorerStatus
    }},
    FIRENEWTS: {name: "Firenewts", details: "ToA 199", more: () => {
        let lightPatrol = "Light patrol: " + RollDice(1, 4) + " firenewt warriors (ToA 219) mounted on giant striders (ToA 222).";
        let heavyPatrol = "Heavy patrol: " + RollDice(2,4) + " firenewt warriors and a firenewt warlock of Imix (ToA 219) mounted on giant striders (ToA 222).";
        return lightPatrol + "\n" + heavyPatrol
    }},
    FLAIL_SNAL: {name: "Flail snail", details: "ToA 199", more: () => {return "1 flail snail (ToA 220)"}},
    FLAMING_FIST: {name: "Flaming Fist", details: "ToA 199", more: () => {
        const enc1 = "1 knight (MM 347) or veteran (MM 350)";
        const enc2 = "1 acolyte (MM 342)";
        const enc3 = "1 scout (MM 349)";
        const enc4 = RollDice(2, 6) + " guards (MM 347)";
        const enc5 = RollDice(1,4) + " deinonychuses (ToA 217)."
        return enc1 + ", " + enc2 + ", " + enc3 + ", " + enc4 + ", and sometimes " + enc5;
    }},
    FLYING_MONKEYS: {name: "Flying monkeys", details: "ToA 200", more: () => {return RollDice(3, 6) + " flying monkeys (ToA 220)."}},
    FLYING_SNAKES: {name: "Flying snakes", details: "ToA 200", more: () => {
        const roll = RollDice(1, 100);
        let flyingSnakes = "";
        if(roll <= 50) {
            flyingSnakes =  "1 flying snake (MM 322)";
        } else {
            flyingSnakes =  RollDice(2, 6) + " flying snakes (MM 322)";
        }
        return flyingSnakes
    }},
    FROST_GIANTS: {name: "Frost giants", details: "ToA 200", more: () => {return "3 frost giants (MM 155) and " + RollDice(1, 2) + " winter wolves (MM 340.)"}},
    GIANT_BOARS: {name: "Giant boars", details: "ToA 200", more: () => {return RollDice(1, 4, 1) + " giant boars (MM 323)."}},
    GIANT_CROCODILE: {name: "Giant crocodile", details: "ToA 200", more: () => {return "1 giant crocodile (MM 324)."}},
    GIANT_FROGS: {name: "Giant frogs", details: "ToA 200", more: () => {return RollDice(2, 6) + " giant frogs (MM 325)."}},
    GIANT_LIZARDS: {name: "Giant lizards", details: "ToA 200", more: () => {return RollDice(1, 6) + " giant lizard(s) (MM 326)."}},
    GIANT_SCORPIONS: {name: "Giant scorpions", details: "ToA 200", more: () => {return RollDice(1, 3) + " giant scorpion(s) (MM 327)."}},
    GIANT_SNAPPING_TURTLE: {name: "Giant snapping turtle", details: "ToA 200", more: () => {return "1 giant snapping turtle (ToA 222)."}},
    GIANT_WASPS: {name:" Giant wasps", details: "ToA 200", more: () => {return RollDice(1, 6) + " giant wasp(s) (MM 329)."}},
    GIRALLONS: {name: "Girallons", details: "ToA 200", more: () => {return "2 girallons (ToA) 222"}},
    GOBLINS: {name: "Goblins", details: "ToA 200", more: () => {return "1 goblin boss and " + RollDice(2, 6, 3) + " goblins (MM 166)"}},
    GRUNGS: {name: "Grungs", details: "ToA 201", more: () => {return "1 grung elite warrior and " + RollDice(2, 6) + " grungs (ToA 223)."}},
    JACULIS: {name: "Jaculis", details: "ToA 201", more: () => {return RollDice(1, 6) + " jaculi(s)  (ToA 225)."}},
    KAMADANS: {name: "Kamadans", details: "ToA 201", more: () => {return RollDice(1, 2) + " kamadans (ToA 225)."}},
    LIZARDFOLK: {name: "Lizardfolk", details: "ToA 201", more: () => {return "1 lizardfolk shaman (MM 205) " + RollDice(2, 4) + " lizardfolk (MM 204)."}},
    MAD_MONKEY_MIST: {name: "Mad monkey mist", details: "ToA 201", more: () => {return "The mist covers " + RollDice(1,6) + " 20-foot squares (ToA 40)."}},
    MAGMINS: {name: "Magmins", details: "ToA 201", more: () => {return RollDice(2,6) + " magmins (MM 212)."}},
    MANTRAPS: {name: "Mantraps", details: "ToA 201", more: () => {return RollDice(1,4,1) + " mantraps (ToA 227)."}},
    MEPHITS: {name: "Mephits", details: "ToA 201", more: () => {
        const mephitTypeRoll = RollDice(1,4);
        let mephitType = "";
        if(mephitTypeRoll === 2) {
            mephitType = "mud mephits (MM 216)"
        } else if(mephitTypeRoll === 3) {
            mephitType = "smoke mephits (MM 217)"
        } else if (mephitTypeRoll === 4) {
            mephitType = "steam mephits (MM 217)"        
        } else {
            mephitType = "magma mephits (MM 216)";
        }
        return RollDice(2,6) + " " + mephitType
    }},
    NIGHTHAG: {name: "Night hag", details: "ToA 201", more: () => {"1 night hag (MM 178)."}},
    PTERAFOLK: {name: "Pterafolk", details: "ToA 201", more: () => {return RollDice(1,4,2) + " pterafolk (ToA 229)."}},
    RARE_PLANTS: {name: "Rare plant(s)", details: "ToA 201", more: () => {
        const roll = RollDice(1,6);
        let plantType = "";
        if(roll === 1) {
            plantType = RollDice(2,6) + " dancing monkey fruit growing on a tree (ToA 205).";
        } else if(roll === 2) {
            plantType = "Menga bush with " + RollDice(2,6) + " ounces of leaves (ToA 205).";
        } else if(roll === 3) {
            plantType = RollDice(1,4) + "ryath roots growing in the ground (ToA 205).";
        } else if(roll === 4) {
            plantType = RollDice(4,6) + "sinda berries growing on a bush (ToA 205).";
        } else if (roll === 5) {
            plantType = "Wukka tree with " + RollDice(2,6) + " wukka nuts (ToA 205).";
        } else if (roll === 6) {
            plantType = RollDice(1,6) + " zabou growing on a dead tree (ToA 205).";
        } else {
            plantType = RollDice(2,6) + " dancing monkey fruit growing on a tree (ToA 205).";
        }
        return plantType
    }},
    REDWIZARD: {name: "Red Wizard", details: "ToA 201", more: () => {return "1 Thayan human mage (MM 347), " +  RollDice(1,6) + " guards (MM 347), and " + RollDice(1,6) + " skeletons (MM 272)."}},
    SALAMANDER: {name: "Salamander", details: "ToA 202", more: () => {return "1 salamander (MM 266) and " + RollDice(1,6) + " fire snakes (MM 265)."}},
    SEAHAGS: {name: "Sea hags", details: "ToA 202",  more: () => {return "3 sea hags (MM 179)."}},
    SHAMBLING_MOUND: {name: "Shambling mound", details: "ToA 202",  more: () => {return "1 shambling mound (MM 270)."}},
    SNAKE_CONSTRICTOR: {name: "Snake, constrictor", details: "ToA 202", more: () => {return "1 constrictor snake (MM 320)."}},
    SNAKE_GIANT_CONSTRICTOR: {name: "Snake, giant constrictor", details: "ToA 202", more: () => {return "1 giant constrictor snake (MM 324)."}},
    SNAKE_GIANT_POISONOUS: {name: "Snake, giant poisonous", details: "ToA 202", more: () => {return "1 giant poisonous snake (MM 327)."}},
    SPIDERS: {name: "Spiders", details: "ToA 202", more: () => {return RollDice(1,6) + " giant spider(s) (MM 328)."}},
    STATUE_OF_UBTAO: {name: "Statue of Ubtao", details: "ToA 202", more: () => {return "roll: " + RollDice(1,4)}},
    STIRGES: {name: "Stirges", details: "ToA 202", more: () => {return RollDice(2,6) + " stirges (MM 284)."}},
    SU_MONSTERS: {name: "Su-monsters", details: "ToA 202", more: () => {return RollDice(1,4,1) + " su-monsters (ToA 232)."}},
    SWARM_OF_BATS: {name: "Swarm of bats", details: "ToA 202", more: () => {return RollDice(1,4) + " swarm of bats (MM 337)."}},
    SWARM_OF_INSECTS: {name: "Swarm of insects", details: "ToA 202", more: () => {return RollDice(1,4) + " swarm of insects (MM 338)."}},
    SWARM_OF_QUIPPERS: {name: "Swarm of quippers", details: "ToA 202", more: () => {return RollDice(1,4) + " swarm of quippers (MM 338)."}},
    TABAXI_HUNTER: {name: "Tabaxi hunter", details: "ToA 203", more: () => {return " 1 tabaxi hunter (ToA 232)."}},
    TIGER: {name: "Tiger", details: "ToA 203", more: () => {return " 1 tiger (MM 339)."}},
    TRI_FLOWER_FROND: {name: "Tri-flower frond", details: "ToA 203", more: () => {return RollDice(1,4) + " tri-flower fronds (ToA 234)."}},
    TROLL: {name: "Troll", details: "ToA 203", more: () => {return "1 troll (MM 291)."}},
    UNDEAD_GHOULS: {name: "Undead, ghouls", details: "TaA 203", more: () => {return "1 ghast and " + RollDice(2,6) + " ghouls (MM 148)."}},
    UNDEAD_SKELETONS: {name: "Undead, skeletons", details: "ToA 203", more: () => {return RollDice(3,6) + " skeletons (MM 272)."}},
    UNDEAD_SPECTER: {name: "Undead, specter", details: "ToA 203", more: () => {return "1 specter (MM 279)."}},
    UNDEAD_WIGHT: {name: "Undead, wight", details: "ToA 203", more: () => {return "1 wight (MM 300)."}},
    UNDEAD_ZOMBIES: {name: "Undead, zombies", details: "ToA 203", more: () => {
        const roll = RollDice(1,10);
        let zombies = "";
        if(roll > 0 && roll <= 3) {
            zombies = RollDice(3,6) + " zombies (MM 316)."
        } else if(roll >= 4 && roll <= 5) {
            zombies = "1 anklyosaurus zombie (ToA 240)."
        } else if(roll >= 6 && roll <= 7) {
            zombies = RollDice(1,4) + " girallon zombie(s) (ToA 240)."
        } else if(roll >= 8 && roll <= 9) {
            zombies = RollDice(1,4) + " ogre zombie(s) (MM 316)."
        } else {
            zombies = "1 tyrannosaurus zombie (ToA 241)."
        }
        return zombies
    }},
    VEGEPYGMIES: {name: "Vegepygmies", details: "ToA 203", more: () => {return RollDice(1,4) + " vegepygmies (ToA 234) each mounted on a thorny (ToA 233)."}},
    WEREBOAR: {name: "Wereboar", details: "ToA 203", more: () => {return "1 wereboar (MM 209)."}},
    WERETIGER: {name: "Weretiger", details: "ToA 203", more: () => {return "1 weretiger (MM 210)."}},
    WINTERSCAPE: {name: "Winterscape", details: "ToA 203", more: () => {return ""}},
    YELLOW_MUSK_CREEPER: {name: "Yellow musk creeper and zombies", details: "ToA 203", more: () => {return "1 yellow musk creeper (ToA 237) and " + RollDice(3,6) + " yellow musk zombies (ToA 237)."}},
    YUAN_TI: {name: "Yuan-ti", details: "ToA 204", more: () => {return RollDice(1,6,1) + " yuan-ti-purebloods (MM 310) OR " + RollDice(1,4) + "yuan-ti malison(s) (MM 309)."}},
    ZHENTARIM: {name: "Zhentarim", details: "ToA 204", more: () => {return "1 assassin (MM 343), 1 flying snake (MM 322), 1 priest (MM 348), " + RollDice(2,6) + " thugs (MM 350), " + RollDice(1,6) + " tribal warrior(s) (MM 350)"}},
    ZORBOS: {name: "Zorbos", details: "ToA 204", more: () => {return RollDice(2,6) + " zorbos (ToA 241)."}},
}

const BeachEncounters = [
    [[1,7], [ENC.AARAKOCRA]],
    [[8,8], [ENC.ARTUS_CIMBER]],
    [[9,10], [ENC.CACHE]],
    [[11,12], [ENC.CHWINGA]],
    [[13,14], [ENC.DINO_ALLOSAURUS]],
    [[15,16], [ENC.DINO_DIMETRODON]],
    [[17,21], [ENC.DINO_PLESIOSAURUS]],
    [[22,28], [ENC.DINO_PTERANODON]],
    [[29,31], [ENC.DINO_QUETZALCOATLUS]],
    [[32,37], [ENC.DINO_VELOCIRAPTOR]],
    [[38,40], [ENC.DRAGON_RED]],
    [[41,42], [ENC.EMERALDENCLAVE]],
    [[43,46], [ENC.EXPLORERS]],
    [[47,49], [ENC.FLAMING_FIST]],
    [[50,52], [ENC.FLYING_MONKEYS]],
    [[53,55], [ENC.FLYING_SNAKES]],
    [[56,57], [ENC.FROST_GIANTS]],
    [[58,63], [ENC.GIANT_LIZARDS]],
    [[64,67], [ENC.GIANT_SNAPPING_TURTLE]],
    [[68,71], [ENC.LIZARDFOLK]],
    [[72,74], [ENC.REDWIZARD]],
    [[75,84], [ENC.SEAHAGS]],
    [[85,87], [ENC.STIRGES]],
    [[88,89], [ENC.SWARM_OF_BATS]],
    [[90,94], [ENC.TABAXI_HUNTER]],
    [[95,100], [ENC.TRI_FLOWER_FROND]],
]

const JungleNoUndeadEncounters = [
    [[1,1], [ENC.ALBINO_DWARVES]],
    [[2,2], [ENC.ALMIRAJ]],
    [[3,4], [ENC.APES]],
    [[5,5], [ENC.ARTUS_CIMBER]],
    [[6,7], [ENC.ASSASSIN_VINES]],
    [[8,8], [ENC.AXE_BEAKS]],
    [[9,9], [ENC.BABOONS]],
    [[10,11], [ENC.CACHE]],
    [[12,13], [ENC.CANNIBALS]],
    [[14,15], [ENC.CHWINGA]],
    [[16,16], [ENC.CYCLOPS]],
    [[17,17], [ENC.DINO_ALLOSAURUS]],
    [[18,18], [ENC.DINO_ANKLYOSAURUS]],
    [[19,19], [ENC.DINO_BRONTOSAURUS]],
    [[20,21], [ENC.DINO_DINONYCHUS]],
    [[22,23], [ENC.DINO_HADROSAURUS]],
    [[24,24], [ENC.DINO_PTERANODON]],
    [[25,26], [ENC.DINO_STEGASAURUS]],
    [[27,28], [ENC.DINO_TRICERATOPS]],
    [[29,30], [ENC.DINO_TYRANNOSAURUS]],
    [[31,35], [ENC.DINO_VELOCIRAPTOR]],
    [[36,36], [ENC.DRAGON_FAERIE]],
    [[37,37], [ENC.EBLIS]],
    [[38,42], [ENC.EMERALDENCLAVE]],
    [[43,44], [ENC.EXPLORER_DEAD]],
    [[45,45], [ENC.EXPLORERS]],
    [[46,46], [ENC.FLAIL_SNAL]],
    [[47,50], [ENC.FLAMING_FIST]],
    [[51,51], [ENC.FLYING_MONKEYS]],
    [[52,53], [ENC.FLYING_SNAKES]],
    [[54,55], [ENC.FROST_GIANTS]],
    [[56,56], [ENC.GIANT_BOARS]],
    [[57,57], [ENC.GIANT_FROGS]],
    [[58,58], [ENC.GIANT_LIZARDS]],
    [[59,59], [ENC.GIANT_SCORPIONS]],
    [[60,60], [ENC.GIANT_WASPS]],
    [[61,52], [ENC.GIRALLONS]],
    [[63,64], [ENC.GOBLINS]],
    [[65,66], [ENC.GRUNGS]],
    [[67,67], [ENC.JACULIS]],
    [[68,68], [ENC.KAMADANS]],
    [[69,70], [ENC.LIZARDFOLK]],
    [[71,72], [ENC.MAD_MONKEY_MIST]],
    [[73,73], [ENC.MANTRAPS]],
    [[74,74], [ENC.NIGHTHAG]],
    [[75,75], [ENC.PTERAFOLK]],
    [[76,76], [ENC.RARE_PLANTS]],
    [[77,77], [ENC.REDWIZARD]],
    [[78,79], [ENC.SNAKE_CONSTRICTOR]],
    [[80,80], [ENC.SNAKE_GIANT_CONSTRICTOR]],
    [[81,81], [ENC.SNAKE_GIANT_POISONOUS]],
    [[82,82], [ENC.SPIDERS]],
    [[83,85], [ENC.STATUE_OF_UBTAO]],
    [[86,86], [ENC.STIRGES]],
    [[87,87], [ENC.SU_MONSTERS]],
    [[88,88], [ENC.SWARM_OF_BATS]],
    [[89,89], [ENC.SWARM_OF_INSECTS]],
    [[90,90], [ENC.TABAXI_HUNTER]],
    [[91,91], [ENC.TIGER]],
    [[92,92], [ENC.TRI_FLOWER_FROND]],
    [[93,93], [ENC.VEGEPYGMIES]],
    [[94,94], [ENC.WEREBOAR]],
    [[95,95], [ENC.WERETIGER]],
    [[96,96], [ENC.WINTERSCAPE]],
    [[97,97], [ENC.YELLOW_MUSK_CREEPER]],
    [[98,98], [ENC.YUAN_TI]],
    [[99,99], [ENC.ZHENTARIM]],
    [[100,100], [ENC.ZORBOS]],
]

const JungleLesserUndeadEncounters = [
    [[1,1], [ENC.ALBINO_DWARVES]],
    [[2,2], [ENC.ARTUS_CIMBER]],
    [[3,5], [ENC.ASSASSIN_VINES]],
    [[6,6], [ENC.AXE_BEAKS]],
    [[7,8], [ENC.CACHE]],
    [[9,10], [ENC.CANNIBALS]],
    [[11,11], [ENC.DINO_ALLOSAURUS]],
    [[12,12], [ENC.DINO_ANKLYOSAURUS]],
    [[13,13], [ENC.DINO_DINONYCHUS]],
    [[14,14], [ENC.DINO_HADROSAURUS]],
    [[15,15], [ENC.DINO_PTERANODON]],
    [[16,16], [ENC.DINO_STEGASAURUS]],
    [[17,17], [ENC.DINO_TRICERATOPS]],
    [[18,18], [ENC.DINO_TYRANNOSAURUS]],
    [[19,20], [ENC.EMERALDENCLAVE]],
    [[21,22], [ENC.EXPLORER_DEAD]],
    [[23,23], [ENC.EXPLORERS]],
    [[24,26], [ENC.FLAMING_FIST]],
    [[27,27], [ENC.FLYING_SNAKES]],
    [[28,28], [ENC.GIANT_LIZARDS]],
    [[29,29], [ENC.GIANT_WASPS]],
    [[30,31], [ENC.GIRALLONS]],
    [[32,33], [ENC.GOBLINS]],
    [[34,35], [ENC.GRUNGS]],
    [[36,39], [ENC.MAD_MONKEY_MIST]],
    [[40,41], [ENC.MANTRAPS]],
    [[42,42], [ENC.NIGHTHAG]],
    [[43,44], [ENC.PTERAFOLK]],
    [[45,45], [ENC.RARE_PLANTS]],
    [[46,46], [ENC.REDWIZARD]],
    [[47,48], [ENC.SNAKE_CONSTRICTOR]],
    [[49,49], [ENC.SNAKE_GIANT_CONSTRICTOR]],
    [[50,50], [ENC.SNAKE_GIANT_POISONOUS]],
    [[51,52], [ENC.SPIDERS]],
    [[53,55], [ENC.STATUE_OF_UBTAO]],
    [[56,57], [ENC.STIRGES]],
    [[58,59], [ENC.SU_MONSTERS]],
    [[60,62], [ENC.SWARM_OF_BATS]],
    [[63,65], [ENC.SWARM_OF_INSECTS]],
    [[66,66], [ENC.TRI_FLOWER_FROND]],
    [[67,67], [ENC.TROLL]],
    [[68,72], [ENC.UNDEAD_GHOULS]],
    [[73,77], [ENC.UNDEAD_SKELETONS]],
    [[78,79], [ENC.UNDEAD_SPECTER]],
    [[80,80], [ENC.UNDEAD_WIGHT]],
    [[81,89], [ENC.UNDEAD_ZOMBIES]],
    [[90,91], [ENC.VEGEPYGMIES]],
    [[92,92], [ENC.WEREBOAR]],
    [[93,93], [ENC.WERETIGER]],
    [[94,94], [ENC.WINTERSCAPE]],
    [[95,96], [ENC.YELLOW_MUSK_CREEPER]],
    [[97,98], [ENC.YUAN_TI]],
    [[99,99], [ENC.ZHENTARIM]],
    [[100,100], [ENC.ZORBOS]],
]

const JungleGreaterUndeadEncounters = [
    [[1,1], [ENC.ARTUS_CIMBER]],
    [[2,2], [ENC.ASSASSIN_VINES]],
    [[3,5], [ENC.CACHE]],
    [[6,6], [ENC.DINO_ALLOSAURUS]],
    [[7,7], [ENC.DINO_ANKLYOSAURUS]],
    [[8,8], [ENC.DINO_HADROSAURUS]],
    [[9,9], [ENC.DINO_PTERANODON]],
    [[10,10], [ENC.DINO_STEGASAURUS]],
    [[11,12], [ENC.DINO_TYRANNOSAURUS]],
    [[13,14], [ENC.DINO_VELOCIRAPTOR]],
    [[15,16], [ENC.EMERALDENCLAVE]],
    [[17,20], [ENC.EXPLORER_DEAD]],
    [[21,21], [ENC.EXPLORERS]],
    [[22,23], [ENC.FLAMING_FIST]],
    [[24,24], [ENC.GIANT_WASPS]],
    [[25,25], [ENC.MANTRAPS]],
    [[26,26], [ENC.PTERAFOLK]],
    [[27,27], [ENC.RARE_PLANTS]],
    [[28,28], [ENC.REDWIZARD]],
    [[29,31], [ENC.SNAKE_CONSTRICTOR]],
    [[32,32], [ENC.SNAKE_GIANT_CONSTRICTOR]],
    [[33,33], [ENC.SNAKE_GIANT_POISONOUS]],
    [[34,36], [ENC.SPIDERS]],
    [[37,40], [ENC.STATUE_OF_UBTAO]],
    [[41,44], [ENC.STIRGES]],
    [[45,45], [ENC.SU_MONSTERS]],
    [[46,46], [ENC.SWARM_OF_BATS]],
    [[47,49], [ENC.SWARM_OF_INSECTS]],
    [[50,50], [ENC.TRI_FLOWER_FROND]],
    [[51,51], [ENC.TROLL]],
    [[52,63], [ENC.UNDEAD_GHOULS]],
    [[64,67], [ENC.UNDEAD_SKELETONS]],
    [[68,70], [ENC.UNDEAD_SPECTER]],
    [[71,73], [ENC.UNDEAD_WIGHT]],
    [[74,85], [ENC.UNDEAD_ZOMBIES]],
    [[86,87], [ENC.VEGEPYGMIES]],
    [[88,89], [ENC.WEREBOAR]],
    [[90,91], [ENC.WERETIGER]],
    [[92,92], [ENC.WINTERSCAPE]],
    [[93,96], [ENC.YELLOW_MUSK_CREEPER]],
    [[97,98], [ENC.YUAN_TI]],
    [[99,100], [ENC.ZORBOS]],
]

const MountainEncounters = [
    [[1,11], [ENC.AARAKOCRA]],
    [[12,17], [ENC.ALBINO_DWARVES]],
    [[18,20], [ENC.APES]],
    [[21,22], [ENC.BABOONS]],
    [[23,25], [ENC.CACHE]],
    [[26,27], [ENC.CHWINGA]],
    [[28,29], [ENC.CYCLOPS]],
    [[30,38], [ENC.DINO_PTERANODON]],
    [[39,42], [ENC.DINO_QUETZALCOATLUS]],
    [[43,45], [ENC.DRAGON_RED]],
    [[47,47], [ENC.EMERALDENCLAVE]],
    [[48,50], [ENC.EXPLORER_DEAD]],
    [[51,53], [ENC.EXPLORERS]],
    [[54,59], [ENC.FLYING_MONKEYS]],
    [[60,61], [ENC.FLYING_SNAKES]],
    [[62,62], [ENC.GIANT_BOARS]],
    [[63,63], [ENC.GIANT_LIZARDS]],
    [[64,65], [ENC.GIANT_WASPS]],
    [[66,70], [ENC.GIRALLONS]],
    [[71,73], [ENC.NIGHTHAG]],
    [[74,80], [ENC.PTERAFOLK]],
    [[81,81], [ENC.REDWIZARD]],
    [[82,84], [ENC.SNAKE_GIANT_POISONOUS]],
    [[85,87], [ENC.STIRGES]],
    [[88,90], [ENC.SWARM_OF_BATS]],
    [[91,92], [ENC.TABAXI_HUNTER]],
    [[93,97], [ENC.TROLL]],
    [[98,99], [ENC.WEREBOAR]],
]

const RiversEncounters = [
    [[1,3], [ENC.AARAKOCRA]],
    [[4,7], [ENC.ALDANI]],
    [[8,9], [ENC.ARTUS_CIMBER]],
    [[10,10], [ENC.ASSASSIN_VINES]],
    [[11,12], [ENC.CACHE]],
    [[13,15], [ENC.CANNIBALS]],
    [[16,18], [ENC.CHWINGA]],
    [[19,23], [ENC.CROCODILES]],
    [[24,24], [ENC.DINO_BRONTOSAURUS]],
    [[25,26], [ENC.DINO_DIMETRODON]],
    [[27,28], [ENC.DINO_HADROSAURUS]],
    [[29,31], [ENC.DINO_PLESIOSAURUS]],
    [[32,34], [ENC.DINO_PTERANODON]],
    [[35,36], [ENC.DINO_QUETZALCOATLUS]],
    [[37,37], [ENC.DRAGON_FAERIE]],
    [[38,40], [ENC.EBLIS]],
    [[41,43], [ENC.EMERALDENCLAVE]],
    [[44,45], [ENC.EXPLORER_DEAD]],
    [[46,49], [ENC.EXPLORERS]],
    [[50,51], [ENC.FLAMING_FIST]],
    [[52,53], [ENC.FLYING_MONKEYS]],
    [[54,55], [ENC.FLYING_SNAKES]],
    [[56,58], [ENC.GIANT_CROCODILE]],
    [[59,60], [ENC.GIANT_FROGS]],
    [[61,62], [ENC.GIANT_SNAPPING_TURTLE]],
    [[63,63], [ENC.GIANT_WASPS]],
    [[64,66], [ENC.GRUNGS]],
    [[67,67], [ENC.JACULIS]],
    [[68,68], [ENC.LIZARDFOLK]],
    [[69,70], [ENC.MAD_MONKEY_MIST]],
    [[71,72], [ENC.PTERAFOLK]],
    [[73,73], [ENC.RARE_PLANTS]],
    [[74,74], [ENC.REDWIZARD]],
    [[75,76], [ENC.SEAHAGS]],
    [[77,79], [ENC.SNAKE_CONSTRICTOR]],
    [[80,80], [ENC.SNAKE_GIANT_CONSTRICTOR]],
    [[81,81], [ENC.STATUE_OF_UBTAO]],
    [[82,83], [ENC.STIRGES]],
    [[84,85], [ENC.SWARM_OF_INSECTS]],
    [[86,91], [ENC.SWARM_OF_QUIPPERS]],
    [[92,93], [ENC.TABAXI_HUNTER]],
    [[94,94], [ENC.UNDEAD_GHOULS]],
    [[95,95], [ENC.UNDEAD_SKELETONS]],
    [[96,96], [ENC.UNDEAD_ZOMBIES]],
    [[97,98], [ENC.YUAN_TI]],
    [[99,100], [ENC.ZHENTARIM]],
]

const RuinsEncounters = [
    [[1,2], [ENC.ALBINO_DWARVES]],
    [[3,3], [ENC.ALMIRAJ]],
    [[4,6], [ENC.APES]],
    [[7,8], [ENC.ARTUS_CIMBER]],
    [[9,12], [ENC.ASSASSIN_VINES]],
    [[13,14], [ENC.BABOONS]],
    [[15,18], [ENC.CACHE]],
    [[19,19], [ENC.CHWINGA]],
    [[20,21], [ENC.CYCLOPS]],
    [[22,22], [ENC.DINO_DINONYCHUS]],
    [[23,23], [ENC.DINO_VELOCIRAPTOR]],
    [[24,26], [ENC.EMERALDENCLAVE]],
    [[27,28], [ENC.EXPLORER_DEAD]],
    [[29,31], [ENC.EXPLORERS]],
    [[32,33], [ENC.FLAIL_SNAL]],
    [[34,36], [ENC.FLAMING_FIST]],
    [[37,38], [ENC.FLYING_MONKEYS]],
    [[39,39], [ENC.FLYING_SNAKES]],
    [[40,41], [ENC.FROST_GIANTS]],
    [[42,42], [ENC.GIANT_LIZARDS]],
    [[43,45], [ENC.GIANT_SCORPIONS]],
    [[46,48], [ENC.GIANT_WASPS]],
    [[49,50], [ENC.GIRALLONS]],
    [[51,52], [ENC.GOBLINS]],
    [[53,54], [ENC.JACULIS]],
    [[55,57], [ENC.KAMADANS]],
    [[58,58], [ENC.LIZARDFOLK]],
    [[59,60], [ENC.MAD_MONKEY_MIST]],
    [[61,61], [ENC.NIGHTHAG]],
    [[62,62], [ENC.RARE_PLANTS]],
    [[63,63], [ENC.REDWIZARD]],
    [[64,66], [ENC.SNAKE_GIANT_POISONOUS]],
    [[67,68], [ENC.SPIDERS]],
    [[69,73], [ENC.STATUE_OF_UBTAO]],
    [[74,75], [ENC.STIRGES]],
    [[76,77], [ENC.SWARM_OF_BATS]],
    [[78,78], [ENC.TABAXI_HUNTER]],
    [[79,80], [ENC.TRI_FLOWER_FROND]],
    [[81,81], [ENC.TROLL]],
    [[82,84], [ENC.UNDEAD_GHOULS]],
    [[85,87], [ENC.UNDEAD_SKELETONS]],
    [[88,89], [ENC.UNDEAD_SPECTER]],
    [[90,91], [ENC.UNDEAD_WIGHT]],
    [[92,93], [ENC.UNDEAD_ZOMBIES]],
    [[94,94], [ENC.WERETIGER]],
    [[95,95], [ENC.WINTERSCAPE]],
    [[96,96], [ENC.YELLOW_MUSK_CREEPER]],
    [[97,98], [ENC.YUAN_TI]],
    [[99,100], [ENC.ZHENTARIM]],
]

const SwampEncounters = [
    [[1,10], [ENC.ALDANI]],
    [[11,11], [ENC.ARTUS_CIMBER]],
    [[12,14], [ENC.ASSASSIN_VINES]],
    [[15,16], [ENC.CHWINGA]],
    [[17,21], [ENC.CROCODILES]],
    [[22,22], [ENC.DINO_ALLOSAURUS]],
    [[23,23], [ENC.DINO_ANKLYOSAURUS]],
    [[24,25], [ENC.DINO_BRONTOSAURUS]],
    [[26,30], [ENC.DINO_DIMETRODON]],
    [[31,33], [ENC.DINO_HADROSAURUS]],
    [[34,35], [ENC.DINO_PTERANODON]],
    [[36,39], [ENC.EBLIS]],
    [[40,41], [ENC.EXPLORER_DEAD]],
    [[42,45], [ENC.EXPLORERS]],
    [[46,47], [ENC.FLAIL_SNAL]],
    [[48,50], [ENC.FLYING_SNAKES]],
    [[51,53], [ENC.GIANT_CROCODILE]],
    [[54,56], [ENC.GIANT_FROGS]],
    [[57,58], [ENC.GIANT_LIZARDS]],
    [[59,60], [ENC.GIANT_SNAPPING_TURTLE]],
    [[61,62], [ENC.GIANT_WASPS]],
    [[63,64], [ENC.GRUNGS]],
    [[65,66], [ENC.LIZARDFOLK]],
    [[67,69], [ENC.MAD_MONKEY_MIST]],
    [[70,70], [ENC.MEPHITS]],
    [[71,71], [ENC.NIGHTHAG]],
    [[72,72], [ENC.RARE_PLANTS]],
    [[73,76], [ENC.SHAMBLING_MOUND]],
    [[77,80], [ENC.SNAKE_CONSTRICTOR]],
    [[81,82], [ENC.SNAKE_GIANT_CONSTRICTOR]],
    [[83,85], [ENC.STATUE_OF_UBTAO]],
    [[86,87], [ENC.STIRGES]],
    [[88,89], [ENC.SWARM_OF_BATS]],
    [[90,94], [ENC.SWARM_OF_QUIPPERS]],
    [[95,95], [ENC.UNDEAD_GHOULS]],
    [[96,97], [ENC.UNDEAD_SKELETONS]],
    [[98,98], [ENC.UNDEAD_ZOMBIES]],
    [[99,99], [ENC.YELLOW_MUSK_CREEPER]],
    [[100,100], [ENC.YUAN_TI]],
]

const WastelandsEncounters = [
    [[1,1], [ENC.ARTUS_CIMBER]],
    [[2,5], [ENC.CACHE]],
    [[6,9], [ENC.DRAGON_FAERIE]],
    [[10,18], [ENC.EXPLORER_DEAD]],
    [[19,19], [ENC.EXPLORERS]],
    [[20,37], [ENC.FIRENEWTS]],
    [[38,45], [ENC.GIANT_SCORPIONS]],
    [[46,54], [ENC.MAGMINS]],
    [[55,71], [ENC.MEPHITS]],
    [[72,78], [ENC.NIGHTHAG]],
    [[79,79], [ENC.STATUE_OF_UBTAO]],
    [[80,83], [ENC.TROLL]],
    [[84,85], [ENC.UNDEAD_GHOULS]],
    [[86,95], [ENC.UNDEAD_SKELETONS]],
    [[96,97], [ENC.UNDEAD_WIGHT]],
    [[98,98], [ENC.UNDEAD_ZOMBIES]],
    [[99,100], [ENC.ZHENTARIM]],
]

const Encounters = {
    beach: BeachEncounters,
    jNoUndead: JungleNoUndeadEncounters,
    jLesserUndead: JungleLesserUndeadEncounters,
    jGreaterUndead: JungleGreaterUndeadEncounters,
    mountains: MountainEncounters,
    rivers: RiversEncounters,
    ruins: RuinsEncounters,
    swamp: SwampEncounters,
    wastelands: WastelandsEncounters,
}

const EncounterRow = ({roll, name, details, more, weather}) => {
    return (
        <div className="container toa-daily-ecnounter">
            <div className="row weather mt-1">
                {weather.rain}
                {weather.wind}
                {weather.temperature}
            </div>
            <div className="row pt-3">
                <div className="col text-roll pl-0">Encounter Table % Roll: {roll}</div>
            </div>
            <div className="row">
                <div className="col-12 text-danger pl-0 d-table">
                    <h4 className="mt-2 mb-0 float-left d-table">{name}</h4>
                    
                </div>
                <div className="col-12 pl-0">
                    <span className="text-details">Encounter description on {details}</span>
                </div>
            </div>

            <div className="row pt-2">
                <div className="col more-info pl-0">{more}</div>
            </div>
        </div>
    )
}


class toaEncounters extends Component {

    state = {
        numDays: 1,
        environment: 'beach',
        encList: undefined,
        encounterOnRoll: 16,
    }

    generateTemp = () => {
        const randTemp = Math.round(Math.random() * d20);
        let tempVariation = undefined;
        let tempMessage = undefined;

        // Generate temperature
        if(randTemp >= 1 && randTemp <= 14) {
            tempMessage = <div><div className="badge temp-normal mr-2 mb-1 float-left"><i class="fas fa-temperature-low mr-2"></i>Normal temp</div></div>
        } else if (randTemp >= 15 && randTemp <= 17) {
            tempVariation = Math.round((Math.random() * d4) * 5);
            tempMessage = <div><div className="badge temp-colder mr-2 mb-1 float-left"><i class="fas fa-temperature-low mr-2"></i>{tempVariation}° COLDER</div></div>
        } else if (randTemp >= 18 && randTemp <= 20) {
            tempVariation = Math.round((Math.random() * d4) * 5);
            tempMessage = <div><div className="badge temp-hotter mr-2 mb-1 float-left"><i class="fas fa-temperature-low mr-2"></i> {tempVariation}° HOTTER</div></div>
        } else {
            tempMessage = <div><div className="badge temp-normal mr-2 mb-1 float-left"><i class="fas fa-temperature-low mr-2"></i>Normal temp</div></div>
        }
        return tempMessage
    }

    generateWind = () => {
        const randWind = Math.round(Math.random() * d20);
        let windMessage = undefined;

        // Generate Wind
        if(randWind >= 1 && randWind <= 12) {
            windMessage = <div><div className="badge wind-none mr-2 mb-1 float-left"><i class="fas fa-wind mr-2"></i>No wind</div></div>
        } else if (randWind >= 13 && randWind <= 17) {
            windMessage = <div><div className="badge wind-light mr-2 mb-1 float-left"><i class="fas fa-wind mr-2"></i>Light wind</div></div>
        } else if (randWind >= 18 && randWind <= 20) {
            windMessage = <div><div className="badge wind-strong mr-2 mb-1 float-left"><i class="fas fa-wind mr-2"></i>Strong wind</div></div>
        } else {
            windMessage = <div><div className="badge wind-none mr-2 mb-1 float-left"><i class="fas fa-wind mr-2"></i>No wind</div></div>
        }
        return windMessage
    }

    generateRain = () => {
        const randRain = Math.round(Math.random() * d20);
        let rainMessage = undefined;

        // Generate Rain
        if(randRain >= 1 && randRain <= 12) {
            rainMessage = <div><div className="badge rain-none mr-2 mb-1 float-left"><i class="fas fa-cloud-rain mr-2"></i>No rain</div></div>
        } else if (randRain >= 13 && randRain <= 17) {
            rainMessage = <div><div className="badge rain-light mr-2 mb-1 float-left"><i class="fas fa-cloud-rain mr-2"></i>Light rain</div></div>
        } else if (randRain >= 18 && randRain <= 20) {
            rainMessage = <div><div className="badge rain-strong mr-2 mb-1 float-left"><i class="fas fa-cloud-rain mr-2"></i>Strong rain</div></div>
        } else {
            rainMessage = <div><div className="badge rain-none mr-2 mb-1 float-left"><i class="fas fa-cloud-rain mr-2"></i>No rain</div></div>
        }
        return rainMessage
    }

    randEncounter = (encounterTable) => {
        //Roll a d20 to see if there is a random encounter
        const randEncounter = Math.round(Math.random() * d100);

        let encounterDesc = undefined;
        let encObj = {};
    
        encounterTable.forEach((encounter) => {
            let rangeLow = encounter[0][0];
            let rangeHigh = encounter[0][1];
            let enc = encounter[1][0];
            if (randEncounter >= rangeLow && randEncounter <= rangeHigh) {
                encObj = {
                    roll: randEncounter,
                    name: enc.name,
                    details: enc.details,
                    more: enc.more()
                }
            }
        });

        if(encounterDesc === undefined) {
            encounterDesc = "No encounter"
        }

        return encObj
    }

    generateEncounters = (e) => {
        e.preventDefault();

        let list = [];

        for (let i = 0; i < this.state.numDays; i++) {
            list.push(this.generateEncounter(i+1));
        }

        this.setState({
            encList: list
        });
    }

    generateEncounter = (day) => {

        let weather = {
            temperature: this.generateTemp(),
            wind: this.generateWind(),
            rain: this.generateRain()
        }

        let encounterType = this.state.environment
        
        let morningRoll = RollDice(1,20);
        let encounter_morning = morningRoll >= this.state.encounterOnRoll ? this.randEncounter(Encounters[encounterType]) : undefined;
        let middayRoll = RollDice(1,20);
        let encounter_midday = middayRoll >= this.state.encounterOnRoll ? this.randEncounter(Encounters[encounterType]) : undefined; 
        let nightRoll = RollDice(1,20);
        let encounter_night = nightRoll >= this.state.encounterOnRoll ? this.randEncounter(Encounters[encounterType]) : undefined; 

        return (
            <tr key={day} className="toa-encounter-row bg-white">
                <th scope="row">{day}</th>
                <td>
                    <div className="container">
                        <div className="row my-3">
                            <div className="col-12 mb-3 encounter py-3">
                                <h3 className="mb-0">Morning (Roll: {morningRoll})</h3>
                                {encounter_morning ? <EncounterRow  roll={encounter_morning.roll} 
                                                            name={encounter_morning.name}
                                                            details={encounter_morning.details}
                                                            more={encounter_morning.more}
                                                            weather={weather}/> : <div>No Encounter<div>{weather.rain}{weather.wind}{weather.temperature}</div></div> 
                                }
                            </div>
                            <div className="col-12 mb-3 encounter py-3">
                                <h3 className="mb-0">Mid-day (Roll: {middayRoll})</h3>
                                {encounter_midday ? <EncounterRow  roll={encounter_midday.roll} 
                                    name={encounter_midday.name}
                                    details={encounter_midday.details}
                                    more={encounter_midday.more}
                                    weather={weather}/> : <div>No Encounter<div>{weather.rain}{weather.wind}{weather.temperature}</div></div> 
                                }  
                            </div>
                            <div className="col-12 mb-3 encounter py-3">
                                <h3 className="mb-0">Night (Roll: {nightRoll})</h3>
                                {encounter_night ? <EncounterRow  roll={encounter_night.roll} 
                                    name={encounter_night.name}
                                    details={encounter_night.details}
                                    more={encounter_night.more}
                                    weather={weather}/> : <div>No Encounter<div>{weather.rain}{weather.wind}{weather.temperature}</div></div> 
                                }  
                            </div>
                        </div>
                    </div>
                                        
                </td>
            </tr>
        );
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    render () {
        return (
            <div className="toa-encounters flex flex-grow-1">
                <div className="container toa-container h-100">
                    {/* Title & Theming */}
                    <div className="row pt-4">
                        <div className="toa-link ml-3">
                            <a href="https://www.amazon.com/Tomb-Annihilation-Dungeons-Dragons-Wizards/dp/0786966106/ref=sr_1_1?keywords=tomb+of+annihilation&qid=1565792981&s=gateway&sr=8-1&tag=aforestdwelli-20">
                                <img className="toa-link-img" alt="Tomb of Annihilation" src={TOAImage}/>
                            </a>
                        </div>
                        <div className="col-4">
                            <h1>Tomb of Annihilation</h1>
                            <h3>Random Encounters</h3>
                        </div>
                    </div>
                    {/* CONTROLS */}
                    <div className="row controls mt-2">
                        <div className="col-12">
                            <form>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <label htmlFor="environment">Environment</label>
                                        <select id="environment" className="form-control" onChange={this.handleChange}>
                                            <option value="beach">Beach</option>
                                            <option value="jNoUndead" selected>Jungle (No Undead)</option>
                                            <option value="jLesserUndead">Jungle (Lesser Undead)</option>
                                            <option value="jGreaterUndead">Jungle (Greater Undead)</option>
                                            <option value="mountains">Mountains</option>
                                            <option value="rivers">Rivers</option>
                                            <option value="ruins">Ruins</option>
                                            <option value="swamp">Swamp</option>
                                            <option value="wasteland">Wasteland</option>
                                        </select>
                                    </div>
                                    <div className="col-6 col-sm-2">
                                        <label htmlFor="encounterOnRoll">Encounter Roll</label>
                                        {/* <input type="text" className="form-control" id="encounterOnRoll" placeholder="16" onChange={this.handleChange}></input> */}
                                        <select id="encounterOnRoll" className="form-control" onChange={this.handleChange}>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16" selected>16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                        </select>
                                    </div>
                                    <div className="col-6 col-sm-4">
                                        <label htmlFor="numDays">Days</label>
                                        <select id="numDays" className="form-control" onChange={this.handleChange}>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <button type="submit" className="btn btn-primary w-100 h-100" onClick={this.generateEncounters}>Generate</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* Output Table */}
                    <div className="row mt-3">
                        <div className="col-12">
                            <table className="encounter-table table">
                                {/* Table Header */}
                                <thead className="thead">
                                    <tr>
                                        <th scope="col">Day</th>
                                        <th scope="col">Encounter</th>
                                    </tr>
                                </thead>
                                {/* Table Body */}
                                <tbody>
                                    {this.state.encList}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>  
        )
    }
}

export default toaEncounters