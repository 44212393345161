export const submitEmail = (email, affiliateID) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
      firestore.collection('mailinglist').add({
        email: email,
        affiliateID: affiliateID,
        createdAt: new Date(),
      }).then(() => {
        dispatch({type: 'EMAIL_SUBMIT_SUCCESS'});
      }).catch((err) => {
        dispatch({type: 'EMAIL_SUBMIT_ERROR', err});
      });
    }
}
