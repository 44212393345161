const initState = {
  emailSubmitted: false,
  submitError: null,
  pageContent: {
    discordLink: 'https://discord.gg/z7bbC7A',
    twitterLink: 'https://twitter.com/tabletopalley'
  }
}

const splashReducer = (state = initState, action) => {
  switch (action.type) {
    case 'EMAIL_SUBMIT_SUCCESS':
      return {
        ...state,
        emailSubmitted: true
      };
    case 'EMAIL_SUBMIT_ERROR':
      console.error('Submitted email failure', action.err);
      return {
        ...state,
        submitError: action.err.message
      };
    default:
      return state
  }
}

export default splashReducer
