import React, { Component } from 'react'
//import M from 'materialize-css'
//import { Link } from 'react-router-dom'
import $ from 'jquery'
//import json2csv from 'json2csv'
import { connect } from 'react-redux'
import { getMailingList } from '../../store/actions/adminActions'
import { CSVLink } from "react-csv";

class BackofficeFrontPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mailingList: undefined,
    }
  }

  handleClick = (e) => {
    const navItems = $("#slide-out > li");

    //Remove active nav items
    for(var i=0; i < navItems.length; i++) {
      let item = navItems[i];
      if($(item).hasClass('active')) {
        $(item).removeClass('active');
      }
    }

    //Add active class to correct items
    const target = e.target;
    $(target).parent().addClass('active');
  }

  handleDownloadEmails = () => {
    console.log('my props', this.props);
    this.props.getMailingList();
  }

  componentDidUpdate(oldProps, newProps) {
    if(this.props.admin.emaiList !== undefined) {
      console.log('Email list is updated');
      console.log(this.props.admin.emaiList);
    }
  }

  render() {
    return(
        <div className="backoffice container flex-grow-1 bg-light">
          <div className="row text-dark">
            <h1>Home</h1>
          </div>
          <div className='row text-dark'>
          {this.props.admin.emaiList ? <CSVLink data={this.props.admin.emaiList}>CSV Ready for Download</CSVLink> : <button className='btn btn-primary' onClick={this.handleDownloadEmails}>Prepare Email List for Download</button>}
          </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.admin
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMailingList: () => dispatch(getMailingList())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackofficeFrontPage)
